
/* mixinsの読み込み */
@import "mixins/include.scss";
/* variablesの読み込み */
@import "_variables.scss";

@import "_common.scss";

/* parts */
@import "parts/_header.scss";
@import "parts/_footer.scss";

/* page */
@import "page/_home.scss";
@import "page/_about.scss";
@import "page/_contact.scss";
@import "page/_recruit.scss";
/* archive */
@import "page/_archive.scss";