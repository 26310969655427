// mixinsの読み込み
@import "./mixins/include.scss";
@import "_variables.scss";

/******************************************
* reset
******************************************/
html, body, body div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, figure, footer, header, menu, nav, section, time, mark, audio, video, details, summary {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
    font-family: $gothic;
}
img,object,embed {max-width: 100%;}
html {
    overflow-y: scroll;
    font-size: 14px;
    line-height: 1.5;
    background-color: white;
}
/******************************************
* font-size
******************************************/
@mixin font-size($size) {
    font-size:#{72 + (7 * ($size - 10)) * 1%};
}
ul {list-style: none;}
a {
    margin: 0;
    padding: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
    text-decoration: none;
}
table {border-collapse: collapse; border-spacing: 0;}
th {font-weight: bold; vertical-align: bottom;}
td {font-weight: normal; vertical-align: top;}
td, td img {vertical-align: top;}
.clickable,label,input[type=button],input[type=submit],input[type=file],button {cursor: pointer;}
button, input, select, textarea {margin: 0;}
button,input[type=button] {width: auto; overflow: visible;}

/******************************************
* ie11のみ適応
******************************************/
@mixin ie11() {
    @media all and (-ms-high-contrast:none){
        @content;
    }
}
/******************************************
* clearfix
******************************************/
.clearfix{
    @include clearfix();
    .left{
        float: left;
    }
    .right{
        float: right;
    }
}

/******************************************
* wrapper
******************************************/
html {
//    scroll-behavior: smooth;
}
.container {
    padding: 0 10px;
    overflow: hidden;
    max-width: 1440px;
    margin: auto;
    &.full_width {
        padding: 0;
        max-width: none;
    }
}
.wrapper{
    width: 100%;
    max-width: 1200px;
    margin: auto;
    &.small {
        width: 980px;
    }
    @include sp {
        width: auto;
        &.small {
            width: auto;
        }
    }
}

/******************************************
* img
******************************************/
img{
    max-width: 100%;
    height: auto;
}

/******************************************
* mt pd
******************************************/
@for $i from 0 through 30 {
    .mt#{$i * 5} {margin-top:#{$i * 5}px!important;}
    .mr#{$i * 5} {margin-right:#{$i * 5}px!important;}
    .mb#{$i * 5} {margin-bottom:#{$i * 5}px!important;}
    .ml#{$i * 5} {margin-left:#{$i * 5}px!important;}

    .pt#{$i * 5} {padding-top:#{$i * 5}px!important;}
    .pr#{$i * 5} {padding-right:#{$i * 5}px!important;}
    .pb#{$i * 5} {padding-bottom:#{$i * 5}px!important;}
    .pl#{$i * 5} {padding-left:#{$i * 5}px!important;}
}

@for $i from 0 through 20 {
    .mt#{$i * -5} {margin-top:#{$i * -5}px!important;}
    .mr#{$i * -5} {margin-right:#{$i * -5}px!important;}
    .mb#{$i * -5} {margin-bottom:#{$i * -5}px!important;}
    .ml#{$i * -5} {margin-left:#{$i * -5}px!important;}
}

/******************************************
* col
******************************************/
.h_row{
    @include clearfix();
    margin-right: -15px;
    &.top{
        .col3{
            .text{
                @include font-size(15);
            }
        }
    }
    .col3,.col4{
        margin-bottom: 30px;
        float: left;
        margin-right: 15px;
    }
    .col3{
        width: 318px;
    }
    .col4{
        width: 234px;
        .title{
            @include font-size(18);
        }
        .text{
            @include font-size(13);
        }
        .btn01{
            @include font-size(13);
        }
    }
    .img_box{
        background:#D8D8D8;
        @include hover-scale();
        overflow: hidden;
        img{
            vertical-align: top;
        }
    }
    .title{
        @include font-size(20);
        color: $text-black;
        letter-spacing: -0.7px;
        padding-top: 10px;
    }
    .text{
        @include font-size(18);
        color: $text-black;
        opacity: 0.7;
        line-height: 1.8;
        padding-top: 3px;
    }
    .btn01{
        margin-top: 10px;
    }

}

.book_row{
    padding-top: 20px;
    @include clearfix();
    margin-right: -60px;
    .col4{
        float: left;
        width: 200px;
        margin-right: 60px;
        padding-bottom: 30px;
        .tit{
            text-align: center;
            @include font-size(20);
        }
        @include hover();
        a{
            color: $text-black;
        }
    }
}

/******************************************
* title
******************************************/
.title01{
    line-height: 1;
    @include font-size(24);
    border-bottom:1px solid #EDEDEE;
    position: relative;
    padding-bottom: 10px;
    font-weight: normal;
    padding-top: 55px;
    margin-bottom: 30px;
    &:after{
        position: absolute;
        display: block;
        content: "";
        height: 2px;
        width: 100px;
        bottom:-1px;
        left:0;
        background:$base-color;
    }
}
.title02{
    line-height: 1;
    @include font-size(22);
    font-weight: normal;
    padding-left: 20px;
    padding-top: 6px;
    padding-bottom: 6px;
    background:#F0F0F0;
    position: relative;
    &:before{
        content: "";
        display: block;
        position: absolute;
        width: 3px;
        height: 70%;
        background:$base-color;
        top: 0;
        bottom:0;
        left: 10px;
        margin: auto;
    }
    .ie &{
        padding-top: 8px;
        padding-bottom: 2px;
    }
    @include ie11{
        padding-top: 8px;
        padding-bottom: 2px;
    }
}

.title03{
    line-height: 1;
    @include font-size(22);
    font-weight: normal;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 12px;
    border-left:5px solid $base-color;
    .ie &{
        padding-top: 6px;
        padding-bottom: 2px;
    }
    @include ie11{
        padding-top: 6px;
        padding-bottom: 2px;
    }
}

/******************************************
* btn
******************************************/
.btn_wrap{
    .btn01{
        display: block;
        text-align: center;
        border:1px solid #EBEBEB;
        line-height:1;
        color: $text-black;
        padding:12px 0;
        a{
            font-weight: normal;
        }
        &.arrow_r{
            &:after{
                content: " →";
            }
        }
        transition: .15s linear;
        &:hover{
            background:$base-color;
            color: #fff;
        }
        .ie &{
            padding-top: 14px;
            padding-bottom: 10px;
        }
        @include ie11{
            padding-top: 14px;
            padding-bottom: 10px;
        }
    }

    .btn02{
        text-align: right;
        padding-top: 15px;
        &.tac{
            text-align: center;
        }
        a{
            font-weight: normal;
            padding:9px 0;
            color: #fff;
            background:$base-color;
            width: 240px;
            display: inline-block;
            @include font-size(16);
            text-align: center;
            @include hover();
            .ie &{
                padding-top: 11px;
                padding-bottom: 7px;
            }
            @include ie11(){
                padding-top: 11px;
                padding-bottom: 7px;
            }
        }
    }
    .btn03{
        a{
            line-height:1;
            border:1px solid $text-black;
            display: inline-block;
            font-weight: normal;
            color: $text-black;
            padding:12px 30px;
            transition: .15s linear;
            &:hover{
                background:$base-color;
                color: #fff;
                border:1px solid #fff;
            }
            .ie &{
                padding-top: 16px;
                padding-bottom: 8px;
            }
            @include ie11(){
                padding-top: 16px;
                padding-bottom: 8px;
            }
        }
        &.arrow_r{
            a:after{
                content: " →";
            }
        }
    }
}

/******************************************
* tbl_style
******************************************/
.tbl_style01{
    @include font-size(14);
    color: #888;
    text-align: center;
    width: 100%;
    tr{
        th,td{
            border:1px solid #979797;
            vertical-align: middle;
            padding:8px 15px;
        }
        th{
            background:#ddd;
        }
    }
}
.tbl_style02{
    @include font-size(18);
    width: 650px;
    margin: auto;
    td,th{
        color: $text-black;
        border:1px solid #979797;
        vertical-align: middle;
    }
    th{
        width: 210px;
        padding:5px 0;
        background:#ddd;
        text-align: left;
        padding-left: 50px;
        box-sizing:border-box;
        letter-spacing: 10px;
    }
    td{
        padding:5px 10px 5px 40px;
    }
}

/******************************************
* common-style
******************************************/
.accent{
    color:$text-red;
}
.tac{text-align: center;}
.tar{text-align: right;}
.tal{text-align: left;}

.err_msg { color: #DB1C2E; }

table.red_blue {
    table-layout: fixed;
    width: 100%;
    margin: 0 auto 100px;
    font-size: 18px;
    line-height: 1.75;
    tbody {
        tr {
            th, td {
                text-align: left;
                vertical-align: middle;
                border-color: #B7B7B7;
                border-width: 1px;
            }
            th {
                background-color: #E8E8E8;
                font-weight: normal;
                padding: 22px 0 22px 45px;
                border-style: solid none solid solid;
                &:first-child {
                    width: 211px;
                }
                &.required::after {
                    content: '※';
                    margin-left: .25em;
                }
            }
            td {
                padding: 20px 25px;
                border-style: solid solid solid none;
                input, select, textarea {
                    -webkit-appearance: none;
                    appearance: none;
                    width: 100%;
                    font-size: 20px;
                    padding: 12px 20px;
                    box-sizing: border-box;
                }
                input, .select_wrap, textarea {
                    border: solid 1px #B7B7B7;
                    border-radius: 5px;
                }
                input.birthday {
                    width: 6em;
                    & + span {
                        margin: 0 .75em 0 .25em;
                    }
                }
                select {
                    background: none;
                    border: none;
                }
                .select_wrap {
                    position: relative;
                    overflow: hidden;
                    z-index: 1;
                    &::before, &::after {
                        content: '';
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        z-index: -1;
                    }
                    &::before {
                        right: 0;
                        width: 14px;
                        background-color: #B7B7B7;
                    }
                    &::after {
                        right: 2px;
                        width: 0;
                        height: 0;
                        border-color: #F1F1F1 transparent;
                        border-style: solid solid none;
                        border-width: 8.6602540375px 5px; // 5 * √3
                        margin: auto;
                    }
                }
                textarea {
                    min-height: 8em;
                    resize: vertical;
                }
                .err_msg {
                    font-size: 14px;
                    margin: 2px 0 -7px;
                    &:empty {
                        display: none;
                    }
                }
            }
            &:first-of-type {
                th { border-top: solid 5px #F3A140; }
                td { border-top: solid 5px #6787C0; }
            }
            &:last-of-type {
                th { border-bottom: solid 5px #F3A140; }
                td { border-bottom: solid 5px #6787C0; }
            }
        }
    }

    @include sp {
        display: block;
        position: relative;
        border-color: #B7B7B7;
        border-style: none solid;
        border-width: 1px;
        margin-bottom: 75px;
        tbody {
            display: block;
            tr {
                display: block;
                th, td {
                    display: block;
                    border-width: 0;
                }
                th {
                    font-size: 15px;
                    font-weight: bold;
                    padding: 7px 15px;
                    border-style: none;
                    &:first-child {
                        width: auto;
                    }
                }
                td {
                    font-size: 15px;
                    padding: 15px 15px;
                    border-style: none;
                    input, select, textarea {
                        font-size: inherit;
                        padding: 7px 10px;
                    }
                    input, .select_wrap, textarea {
                        border: solid 1px #ccc;
                    }
                    input.birthday {
                        width: 4em;
                        & + span {
                            margin: 0 .5em 0 .125em;
                        }
                    }
                    .select_wrap {
                        &::before {
                            width: 14px;
                            background-color: #ccc;
                        }
                        &::after {
                            right: 3px;
                            border-width: 6.92820323px 4px; // 4 * √3
                        }
                    }
                    .err_msg {
                        font-size: 12px;
                        margin: 2px 0 -3px;
                    }
                }
                &:first-of-type {
                    th { border-top: none; }
                    td { border-top: none; }
                }
                &:last-of-type {
                    th { border-bottom: none; }
                    td { border-bottom: none; }
                }
            }
        }
        &::before, &::after {
            content: '';
            display: block;
            width: 100%;
            height: 3px;
            padding: 0 1px;
            margin-left: -1px; //padding width 1px
            background-color: #DB1C2E;
            background-image: linear-gradient(90deg, #DB1C2E, #DB1C2E 30%, #004D9A 30%, #004D9A);
        }
    }
}
.head_privacy {
    font-size: 24px;
    font-weight: normal;
    margin-bottom: 20px;
    @include sp {
        font-size: 22px;
    }
}
.box_privacy {
    font-size: 18px;
    line-height: 1.75;
    border: solid 1px #979797;
    padding: 40px 60px;
    box-sizing: border-box;
    height: 280px;
    overflow: auto;
    margin-bottom: 80px;
    .prelude {
        margin-bottom: 40px;
    }
    .ti-1 {
        text-indent: 1em;
    }
    dl.clause {
        dt {
            font-size: 20px;
            font-weight: bold;
        }
        dd {
            ul {
                list-style: none;
                padding-left: 40px;
                margin: 40px 0;
            }
            &.indent {
                padding-left: 1em;
            }
            & + dt {
                margin-top: 40px;
            }
        }
    }
    dl.contact {
        dt {
            font-size: inherit;
            font-weight: normal;
        }
    }
    @include sp {
        font-size: 14px;
        padding: 30px 15px;
        box-sizing: border-box;
        margin-bottom: 50px;
        .prelude {
            margin-bottom: 20px;
        }
        dl.clause {
            dt {
                font-size: 16px;
            }
            dd {
                ul {
                    list-style: none;
                    padding-left: 15px;
                    margin: 15px 0;
                }
                &.indent {
                    padding-left: 1em;
                }
                & + dt {
                    margin-top: 20px;
                }
            }
        }
    }
}
.btn_fat {
    display: inline-block;
    background-color: #004D9A;
    color: white;
    font-size: 28px;
    font-weight: bold;
    min-width: 466px;
    text-align: center;
    padding: 20px;
    border: none;
    border-radius: 8px;
    transition: opacity .3s;
    box-sizing: border-box;
    margin-bottom: 5px;
    &:hover, &:focus {
        opacity: .75;
    }
    * {
        vertical-align: middle;
    }
    &.red {
        background-color: #DB1C2E;
    }
    .icon {
        margin-right: .5em;
    }
    &.with_triangle {
        &::after {
            content: '';
            display: inline-block;
            width: 0;
            height: 0;
            vertical-align: middle;
            border-color: transparent currentColor;
            border-style: solid none solid solid;
            border-width: .4em .692820323em; // .4 * √3
            margin-left: .25em;
        }
    }
    @include sp {
        font-size: 22px;
        min-width: 250px;
        padding: 15px 20px;
        .icon {
            width: auto;
            height: 22px;
            margin-right: .25em;
        }
    }
}
.visual_header {
    background-image: url(./shared/images/visual_header.jpg);
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    height: 290px;
    @media only screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
        background-image: url(./shared/images/visual_header@2x.jpg);
    }
    .visual_title {
        color: white;
        font-size: 38px;
        font-weight: bold;
        text-align: center;
        line-height: 290px;
        white-space: nowrap;
    }
    @include sp {
        height: 120px;
        .visual_title {
            font-size: 24px;
            line-height: 120px;
        }
    }
}
.parallax {
//    background-attachment: fixed;
    @include sp {
        background-attachment: scroll;
    }
}
.agent_site_btn {
    display: inline-block;
    background-color: #CF2B1E;
    background-image: linear-gradient(110deg, #CF2B1E, #CF2B1E 49.9%, #1B4A98 50.1%, #1B4A98); //50%, 50% だとギザギザになるので ±.1
    background-repeat: no-repeat;
    color: white;
    font-size: 14px;
    padding: 7px 38px;
    transition: opacity .3s;
    &:hover, &:focus {
        opacity: .75;
    }
}
.link_tel {
    color: inherit;
    * {
        color: inherit;
        vertical-align: middle;
    }
}
.ws-pl {
    white-space: pre-line;
}

//sec
.sec {
    padding: 80px 0;
}
.sec_gray {
    background-color: #F2F2F2;
}

//text-align
.al-c{
    text-align: center;
}
.al-l{
    text-align: left;
}
.al-r{
    text-align: right;
}