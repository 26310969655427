#header {
    padding: 25px 0;
    .container {
        display: flex;
        align-items: center;
        min-height: 90px;
    }
    .wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .logo {
            transition: opacity .3s;
            &:hover {
                opacity: .75;
            }
        }
        .nav {
            flex: 1;
        }
        .top_right {
            text-align: right;
            margin-bottom: 20px;
            ul {
                display: inline-block;
                li {
                    display: inline-block;
                    * {
                        vertical-align: middle;
                    }
                    & + li {
                        margin-left: 50px;
                    }
                }
            }
            .link_tel,
            .link_mail {
                transition: opacity .3s;
                &:hover {
                    opacity: .6;
                }
                .tel {
                    font-size: 24px;
                    margin-left: .25em;
                }
            }
        }
    }
    #menu {
        font-size: 14px;
        text-align: right;
        margin-left: 30px;
        ul {
            display: inline-block;
            li {
                display: inline-block;
                padding-bottom: 7px;
                margin: 15px 0 0 20px;
                &:last-of-type {
                    margin-right: 0;
                }
                a {
                    color: inherit;
                    padding: 0 10px 8px;
                    border-bottom: 2px solid transparent;
                    transition: border-bottom .3s;
                    &.curr,
                    &:hover {
                        color:#db1c2e;
                        border-bottom: 2px solid #db1c2e;
                    }
                }
            }
        }
        .agent_site_btn {
            display: none;
        }
    }
    #btn_menu_open,
    #btn_menu_close {
        display: none;
        &:focus {
            outline: none;
        }
    }

    @include sp {
        padding: 15px 5px 15px 15px;
        .container {
            min-height: 0;
        }
        .wrapper {
            width: 100%;
            .logo {
                width: auto;
                height: 50px;
                float: left;
            }
            .nav {
                position: absolute;
                .top_right {
                    display: none;
                }
            }
            #menu {
                position: fixed;
                background-color: #1D68B3;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                padding: 15px;
                color: white;
                font-size: 18px;
                font-weight: bold;
                text-align: center;
                margin: 0;
                opacity: 0;
                z-index: -1;
                &.show {
                    transition: opacity .3s, z-index .3s step-start;
                    opacity: 1;
                    z-index: 2147483647;
                }
                &.hide {
                    transition: opacity .3s, z-index .3s step-end;
                }
                ul {
                    display: block;
                    text-align: center;
                    margin-bottom: 40px;
                    li {
                        display: block;
                        border-top: solid 2px #D8D8D8;
                        padding: 0;
                        margin: 0;
                        &:last-of-type {
                            border-bottom: solid 2px #D8D8D8;
                        }
                        a {
                            display: block;
                            color: inherit;
                            padding: 15px 0;
                            border-bottom: 0;
                            &.curr,
                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }
                .agent_site_btn {
                    display: inline-block;
                    font-size: 16px;
                    padding: 15px 30px;
                }
            }
        }
        #btn_menu_open,
        #btn_menu_close {
            background: none;
            border: none;
            padding: 0;
            width: 50px;
        }
        #btn_menu_open {
            display: block;
        }
        #btn_menu_close {
            display: inline-block;
            margin-bottom: 15px;
        }
    }
}
