body.archive{
    .head_info {
        font-size: 24px;
        font-weight: normal;
        margin-bottom: 30px;
    }
    .list_info {
        list-style: none;
        padding-left: 0;
        li {
            display: table;
            table-layout: fixed;
            width: 100%;
            background-color: white;
            font-size: 14px;
            padding: 10px 30px;
            margin-bottom: 4px;
            box-sizing: border-box;
            time {
                display: table-cell;
                width: 7em;
            }
            p {
                display: table-cell;
            }
        }
    }
}