@charset "UTF-8";
/* mixinsの読み込み */
/* variablesの読み込み */
/******************************************
* reset
******************************************/
html, body, body div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, figure, footer, header, menu, nav, section, time, mark, audio, video, details, summary {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  font-family: 游ゴシック体, "Yu Gothic", YuGothic, "ヒラギノ角ゴシック Pro", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif; }

img, object, embed {
  max-width: 100%; }

html {
  overflow-y: scroll;
  font-size: 14px;
  line-height: 1.5;
  background-color: white; }

/******************************************
* font-size
******************************************/
ul {
  list-style: none; }

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  text-decoration: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

th {
  font-weight: bold;
  vertical-align: bottom; }

td {
  font-weight: normal;
  vertical-align: top; }

td, td img {
  vertical-align: top; }

.clickable, label, input[type=button], input[type=submit], input[type=file], button {
  cursor: pointer; }

button, input, select, textarea {
  margin: 0; }

button, input[type=button] {
  width: auto;
  overflow: visible; }

/******************************************
* ie11のみ適応
******************************************/
/******************************************
* clearfix
******************************************/
.clearfix:before, .clearfix:after {
  content: " ";
  display: table; }

.clearfix:after {
  clear: both; }

.clearfix .left {
  float: left; }

.clearfix .right {
  float: right; }

/******************************************
* wrapper
******************************************/
.container {
  padding: 0 10px;
  overflow: hidden;
  max-width: 1440px;
  margin: auto; }
  .container.full_width {
    padding: 0;
    max-width: none; }

.wrapper {
  width: 100%;
  max-width: 1200px;
  margin: auto; }
  .wrapper.small {
    width: 980px; }
  @media screen and (min-width: 0) and (max-width: 767px) {
    .wrapper {
      width: auto; }
      .wrapper.small {
        width: auto; } }

/******************************************
* img
******************************************/
img {
  max-width: 100%;
  height: auto; }

/******************************************
* mt pd
******************************************/
.mt0 {
  margin-top: 0px !important; }

.mr0 {
  margin-right: 0px !important; }

.mb0 {
  margin-bottom: 0px !important; }

.ml0 {
  margin-left: 0px !important; }

.pt0 {
  padding-top: 0px !important; }

.pr0 {
  padding-right: 0px !important; }

.pb0 {
  padding-bottom: 0px !important; }

.pl0 {
  padding-left: 0px !important; }

.mt5 {
  margin-top: 5px !important; }

.mr5 {
  margin-right: 5px !important; }

.mb5 {
  margin-bottom: 5px !important; }

.ml5 {
  margin-left: 5px !important; }

.pt5 {
  padding-top: 5px !important; }

.pr5 {
  padding-right: 5px !important; }

.pb5 {
  padding-bottom: 5px !important; }

.pl5 {
  padding-left: 5px !important; }

.mt10 {
  margin-top: 10px !important; }

.mr10 {
  margin-right: 10px !important; }

.mb10 {
  margin-bottom: 10px !important; }

.ml10 {
  margin-left: 10px !important; }

.pt10 {
  padding-top: 10px !important; }

.pr10 {
  padding-right: 10px !important; }

.pb10 {
  padding-bottom: 10px !important; }

.pl10 {
  padding-left: 10px !important; }

.mt15 {
  margin-top: 15px !important; }

.mr15 {
  margin-right: 15px !important; }

.mb15 {
  margin-bottom: 15px !important; }

.ml15 {
  margin-left: 15px !important; }

.pt15 {
  padding-top: 15px !important; }

.pr15 {
  padding-right: 15px !important; }

.pb15 {
  padding-bottom: 15px !important; }

.pl15 {
  padding-left: 15px !important; }

.mt20 {
  margin-top: 20px !important; }

.mr20 {
  margin-right: 20px !important; }

.mb20 {
  margin-bottom: 20px !important; }

.ml20 {
  margin-left: 20px !important; }

.pt20 {
  padding-top: 20px !important; }

.pr20 {
  padding-right: 20px !important; }

.pb20 {
  padding-bottom: 20px !important; }

.pl20 {
  padding-left: 20px !important; }

.mt25 {
  margin-top: 25px !important; }

.mr25 {
  margin-right: 25px !important; }

.mb25 {
  margin-bottom: 25px !important; }

.ml25 {
  margin-left: 25px !important; }

.pt25 {
  padding-top: 25px !important; }

.pr25 {
  padding-right: 25px !important; }

.pb25 {
  padding-bottom: 25px !important; }

.pl25 {
  padding-left: 25px !important; }

.mt30 {
  margin-top: 30px !important; }

.mr30 {
  margin-right: 30px !important; }

.mb30 {
  margin-bottom: 30px !important; }

.ml30 {
  margin-left: 30px !important; }

.pt30 {
  padding-top: 30px !important; }

.pr30 {
  padding-right: 30px !important; }

.pb30 {
  padding-bottom: 30px !important; }

.pl30 {
  padding-left: 30px !important; }

.mt35 {
  margin-top: 35px !important; }

.mr35 {
  margin-right: 35px !important; }

.mb35 {
  margin-bottom: 35px !important; }

.ml35 {
  margin-left: 35px !important; }

.pt35 {
  padding-top: 35px !important; }

.pr35 {
  padding-right: 35px !important; }

.pb35 {
  padding-bottom: 35px !important; }

.pl35 {
  padding-left: 35px !important; }

.mt40 {
  margin-top: 40px !important; }

.mr40 {
  margin-right: 40px !important; }

.mb40 {
  margin-bottom: 40px !important; }

.ml40 {
  margin-left: 40px !important; }

.pt40 {
  padding-top: 40px !important; }

.pr40 {
  padding-right: 40px !important; }

.pb40 {
  padding-bottom: 40px !important; }

.pl40 {
  padding-left: 40px !important; }

.mt45 {
  margin-top: 45px !important; }

.mr45 {
  margin-right: 45px !important; }

.mb45 {
  margin-bottom: 45px !important; }

.ml45 {
  margin-left: 45px !important; }

.pt45 {
  padding-top: 45px !important; }

.pr45 {
  padding-right: 45px !important; }

.pb45 {
  padding-bottom: 45px !important; }

.pl45 {
  padding-left: 45px !important; }

.mt50 {
  margin-top: 50px !important; }

.mr50 {
  margin-right: 50px !important; }

.mb50 {
  margin-bottom: 50px !important; }

.ml50 {
  margin-left: 50px !important; }

.pt50 {
  padding-top: 50px !important; }

.pr50 {
  padding-right: 50px !important; }

.pb50 {
  padding-bottom: 50px !important; }

.pl50 {
  padding-left: 50px !important; }

.mt55 {
  margin-top: 55px !important; }

.mr55 {
  margin-right: 55px !important; }

.mb55 {
  margin-bottom: 55px !important; }

.ml55 {
  margin-left: 55px !important; }

.pt55 {
  padding-top: 55px !important; }

.pr55 {
  padding-right: 55px !important; }

.pb55 {
  padding-bottom: 55px !important; }

.pl55 {
  padding-left: 55px !important; }

.mt60 {
  margin-top: 60px !important; }

.mr60 {
  margin-right: 60px !important; }

.mb60 {
  margin-bottom: 60px !important; }

.ml60 {
  margin-left: 60px !important; }

.pt60 {
  padding-top: 60px !important; }

.pr60 {
  padding-right: 60px !important; }

.pb60 {
  padding-bottom: 60px !important; }

.pl60 {
  padding-left: 60px !important; }

.mt65 {
  margin-top: 65px !important; }

.mr65 {
  margin-right: 65px !important; }

.mb65 {
  margin-bottom: 65px !important; }

.ml65 {
  margin-left: 65px !important; }

.pt65 {
  padding-top: 65px !important; }

.pr65 {
  padding-right: 65px !important; }

.pb65 {
  padding-bottom: 65px !important; }

.pl65 {
  padding-left: 65px !important; }

.mt70 {
  margin-top: 70px !important; }

.mr70 {
  margin-right: 70px !important; }

.mb70 {
  margin-bottom: 70px !important; }

.ml70 {
  margin-left: 70px !important; }

.pt70 {
  padding-top: 70px !important; }

.pr70 {
  padding-right: 70px !important; }

.pb70 {
  padding-bottom: 70px !important; }

.pl70 {
  padding-left: 70px !important; }

.mt75 {
  margin-top: 75px !important; }

.mr75 {
  margin-right: 75px !important; }

.mb75 {
  margin-bottom: 75px !important; }

.ml75 {
  margin-left: 75px !important; }

.pt75 {
  padding-top: 75px !important; }

.pr75 {
  padding-right: 75px !important; }

.pb75 {
  padding-bottom: 75px !important; }

.pl75 {
  padding-left: 75px !important; }

.mt80 {
  margin-top: 80px !important; }

.mr80 {
  margin-right: 80px !important; }

.mb80 {
  margin-bottom: 80px !important; }

.ml80 {
  margin-left: 80px !important; }

.pt80 {
  padding-top: 80px !important; }

.pr80 {
  padding-right: 80px !important; }

.pb80 {
  padding-bottom: 80px !important; }

.pl80 {
  padding-left: 80px !important; }

.mt85 {
  margin-top: 85px !important; }

.mr85 {
  margin-right: 85px !important; }

.mb85 {
  margin-bottom: 85px !important; }

.ml85 {
  margin-left: 85px !important; }

.pt85 {
  padding-top: 85px !important; }

.pr85 {
  padding-right: 85px !important; }

.pb85 {
  padding-bottom: 85px !important; }

.pl85 {
  padding-left: 85px !important; }

.mt90 {
  margin-top: 90px !important; }

.mr90 {
  margin-right: 90px !important; }

.mb90 {
  margin-bottom: 90px !important; }

.ml90 {
  margin-left: 90px !important; }

.pt90 {
  padding-top: 90px !important; }

.pr90 {
  padding-right: 90px !important; }

.pb90 {
  padding-bottom: 90px !important; }

.pl90 {
  padding-left: 90px !important; }

.mt95 {
  margin-top: 95px !important; }

.mr95 {
  margin-right: 95px !important; }

.mb95 {
  margin-bottom: 95px !important; }

.ml95 {
  margin-left: 95px !important; }

.pt95 {
  padding-top: 95px !important; }

.pr95 {
  padding-right: 95px !important; }

.pb95 {
  padding-bottom: 95px !important; }

.pl95 {
  padding-left: 95px !important; }

.mt100 {
  margin-top: 100px !important; }

.mr100 {
  margin-right: 100px !important; }

.mb100 {
  margin-bottom: 100px !important; }

.ml100 {
  margin-left: 100px !important; }

.pt100 {
  padding-top: 100px !important; }

.pr100 {
  padding-right: 100px !important; }

.pb100 {
  padding-bottom: 100px !important; }

.pl100 {
  padding-left: 100px !important; }

.mt105 {
  margin-top: 105px !important; }

.mr105 {
  margin-right: 105px !important; }

.mb105 {
  margin-bottom: 105px !important; }

.ml105 {
  margin-left: 105px !important; }

.pt105 {
  padding-top: 105px !important; }

.pr105 {
  padding-right: 105px !important; }

.pb105 {
  padding-bottom: 105px !important; }

.pl105 {
  padding-left: 105px !important; }

.mt110 {
  margin-top: 110px !important; }

.mr110 {
  margin-right: 110px !important; }

.mb110 {
  margin-bottom: 110px !important; }

.ml110 {
  margin-left: 110px !important; }

.pt110 {
  padding-top: 110px !important; }

.pr110 {
  padding-right: 110px !important; }

.pb110 {
  padding-bottom: 110px !important; }

.pl110 {
  padding-left: 110px !important; }

.mt115 {
  margin-top: 115px !important; }

.mr115 {
  margin-right: 115px !important; }

.mb115 {
  margin-bottom: 115px !important; }

.ml115 {
  margin-left: 115px !important; }

.pt115 {
  padding-top: 115px !important; }

.pr115 {
  padding-right: 115px !important; }

.pb115 {
  padding-bottom: 115px !important; }

.pl115 {
  padding-left: 115px !important; }

.mt120 {
  margin-top: 120px !important; }

.mr120 {
  margin-right: 120px !important; }

.mb120 {
  margin-bottom: 120px !important; }

.ml120 {
  margin-left: 120px !important; }

.pt120 {
  padding-top: 120px !important; }

.pr120 {
  padding-right: 120px !important; }

.pb120 {
  padding-bottom: 120px !important; }

.pl120 {
  padding-left: 120px !important; }

.mt125 {
  margin-top: 125px !important; }

.mr125 {
  margin-right: 125px !important; }

.mb125 {
  margin-bottom: 125px !important; }

.ml125 {
  margin-left: 125px !important; }

.pt125 {
  padding-top: 125px !important; }

.pr125 {
  padding-right: 125px !important; }

.pb125 {
  padding-bottom: 125px !important; }

.pl125 {
  padding-left: 125px !important; }

.mt130 {
  margin-top: 130px !important; }

.mr130 {
  margin-right: 130px !important; }

.mb130 {
  margin-bottom: 130px !important; }

.ml130 {
  margin-left: 130px !important; }

.pt130 {
  padding-top: 130px !important; }

.pr130 {
  padding-right: 130px !important; }

.pb130 {
  padding-bottom: 130px !important; }

.pl130 {
  padding-left: 130px !important; }

.mt135 {
  margin-top: 135px !important; }

.mr135 {
  margin-right: 135px !important; }

.mb135 {
  margin-bottom: 135px !important; }

.ml135 {
  margin-left: 135px !important; }

.pt135 {
  padding-top: 135px !important; }

.pr135 {
  padding-right: 135px !important; }

.pb135 {
  padding-bottom: 135px !important; }

.pl135 {
  padding-left: 135px !important; }

.mt140 {
  margin-top: 140px !important; }

.mr140 {
  margin-right: 140px !important; }

.mb140 {
  margin-bottom: 140px !important; }

.ml140 {
  margin-left: 140px !important; }

.pt140 {
  padding-top: 140px !important; }

.pr140 {
  padding-right: 140px !important; }

.pb140 {
  padding-bottom: 140px !important; }

.pl140 {
  padding-left: 140px !important; }

.mt145 {
  margin-top: 145px !important; }

.mr145 {
  margin-right: 145px !important; }

.mb145 {
  margin-bottom: 145px !important; }

.ml145 {
  margin-left: 145px !important; }

.pt145 {
  padding-top: 145px !important; }

.pr145 {
  padding-right: 145px !important; }

.pb145 {
  padding-bottom: 145px !important; }

.pl145 {
  padding-left: 145px !important; }

.mt150 {
  margin-top: 150px !important; }

.mr150 {
  margin-right: 150px !important; }

.mb150 {
  margin-bottom: 150px !important; }

.ml150 {
  margin-left: 150px !important; }

.pt150 {
  padding-top: 150px !important; }

.pr150 {
  padding-right: 150px !important; }

.pb150 {
  padding-bottom: 150px !important; }

.pl150 {
  padding-left: 150px !important; }

.mt0 {
  margin-top: 0px !important; }

.mr0 {
  margin-right: 0px !important; }

.mb0 {
  margin-bottom: 0px !important; }

.ml0 {
  margin-left: 0px !important; }

.mt-5 {
  margin-top: -5px !important; }

.mr-5 {
  margin-right: -5px !important; }

.mb-5 {
  margin-bottom: -5px !important; }

.ml-5 {
  margin-left: -5px !important; }

.mt-10 {
  margin-top: -10px !important; }

.mr-10 {
  margin-right: -10px !important; }

.mb-10 {
  margin-bottom: -10px !important; }

.ml-10 {
  margin-left: -10px !important; }

.mt-15 {
  margin-top: -15px !important; }

.mr-15 {
  margin-right: -15px !important; }

.mb-15 {
  margin-bottom: -15px !important; }

.ml-15 {
  margin-left: -15px !important; }

.mt-20 {
  margin-top: -20px !important; }

.mr-20 {
  margin-right: -20px !important; }

.mb-20 {
  margin-bottom: -20px !important; }

.ml-20 {
  margin-left: -20px !important; }

.mt-25 {
  margin-top: -25px !important; }

.mr-25 {
  margin-right: -25px !important; }

.mb-25 {
  margin-bottom: -25px !important; }

.ml-25 {
  margin-left: -25px !important; }

.mt-30 {
  margin-top: -30px !important; }

.mr-30 {
  margin-right: -30px !important; }

.mb-30 {
  margin-bottom: -30px !important; }

.ml-30 {
  margin-left: -30px !important; }

.mt-35 {
  margin-top: -35px !important; }

.mr-35 {
  margin-right: -35px !important; }

.mb-35 {
  margin-bottom: -35px !important; }

.ml-35 {
  margin-left: -35px !important; }

.mt-40 {
  margin-top: -40px !important; }

.mr-40 {
  margin-right: -40px !important; }

.mb-40 {
  margin-bottom: -40px !important; }

.ml-40 {
  margin-left: -40px !important; }

.mt-45 {
  margin-top: -45px !important; }

.mr-45 {
  margin-right: -45px !important; }

.mb-45 {
  margin-bottom: -45px !important; }

.ml-45 {
  margin-left: -45px !important; }

.mt-50 {
  margin-top: -50px !important; }

.mr-50 {
  margin-right: -50px !important; }

.mb-50 {
  margin-bottom: -50px !important; }

.ml-50 {
  margin-left: -50px !important; }

.mt-55 {
  margin-top: -55px !important; }

.mr-55 {
  margin-right: -55px !important; }

.mb-55 {
  margin-bottom: -55px !important; }

.ml-55 {
  margin-left: -55px !important; }

.mt-60 {
  margin-top: -60px !important; }

.mr-60 {
  margin-right: -60px !important; }

.mb-60 {
  margin-bottom: -60px !important; }

.ml-60 {
  margin-left: -60px !important; }

.mt-65 {
  margin-top: -65px !important; }

.mr-65 {
  margin-right: -65px !important; }

.mb-65 {
  margin-bottom: -65px !important; }

.ml-65 {
  margin-left: -65px !important; }

.mt-70 {
  margin-top: -70px !important; }

.mr-70 {
  margin-right: -70px !important; }

.mb-70 {
  margin-bottom: -70px !important; }

.ml-70 {
  margin-left: -70px !important; }

.mt-75 {
  margin-top: -75px !important; }

.mr-75 {
  margin-right: -75px !important; }

.mb-75 {
  margin-bottom: -75px !important; }

.ml-75 {
  margin-left: -75px !important; }

.mt-80 {
  margin-top: -80px !important; }

.mr-80 {
  margin-right: -80px !important; }

.mb-80 {
  margin-bottom: -80px !important; }

.ml-80 {
  margin-left: -80px !important; }

.mt-85 {
  margin-top: -85px !important; }

.mr-85 {
  margin-right: -85px !important; }

.mb-85 {
  margin-bottom: -85px !important; }

.ml-85 {
  margin-left: -85px !important; }

.mt-90 {
  margin-top: -90px !important; }

.mr-90 {
  margin-right: -90px !important; }

.mb-90 {
  margin-bottom: -90px !important; }

.ml-90 {
  margin-left: -90px !important; }

.mt-95 {
  margin-top: -95px !important; }

.mr-95 {
  margin-right: -95px !important; }

.mb-95 {
  margin-bottom: -95px !important; }

.ml-95 {
  margin-left: -95px !important; }

.mt-100 {
  margin-top: -100px !important; }

.mr-100 {
  margin-right: -100px !important; }

.mb-100 {
  margin-bottom: -100px !important; }

.ml-100 {
  margin-left: -100px !important; }

/******************************************
* col
******************************************/
.h_row {
  margin-right: -15px; }
  .h_row:before, .h_row:after {
    content: " ";
    display: table; }
  .h_row:after {
    clear: both; }
  .h_row.top .col3 .text {
    font-size: 107%; }
  .h_row .col3, .h_row .col4 {
    margin-bottom: 30px;
    float: left;
    margin-right: 15px; }
  .h_row .col3 {
    width: 318px; }
  .h_row .col4 {
    width: 234px; }
    .h_row .col4 .title {
      font-size: 128%; }
    .h_row .col4 .text {
      font-size: 93%; }
    .h_row .col4 .btn01 {
      font-size: 93%; }
  .h_row .img_box {
    background: #D8D8D8;
    overflow: hidden; }
    .h_row .img_box img {
      transition: transform 0.75s cubic-bezier(0.34, 0.1, 0.43, 0.95); }
    .h_row .img_box:hover img {
      transform: scale(1.05); }
    .h_row .img_box img {
      vertical-align: top; }
  .h_row .title {
    font-size: 142%;
    color: #202124;
    letter-spacing: -0.7px;
    padding-top: 10px; }
  .h_row .text {
    font-size: 128%;
    color: #202124;
    opacity: 0.7;
    line-height: 1.8;
    padding-top: 3px; }
  .h_row .btn01 {
    margin-top: 10px; }

.book_row {
  padding-top: 20px;
  margin-right: -60px; }
  .book_row:before, .book_row:after {
    content: " ";
    display: table; }
  .book_row:after {
    clear: both; }
  .book_row .col4 {
    float: left;
    width: 200px;
    margin-right: 60px;
    padding-bottom: 30px;
    transition: opacity .2s; }
    .book_row .col4 .tit {
      text-align: center;
      font-size: 142%; }
    .book_row .col4:hover {
      filter: alpha(opacity=70);
      -moz-opacity: 0.7;
      opacity: 0.7; }
    .book_row .col4 a {
      color: #202124; }

/******************************************
* title
******************************************/
.title01 {
  line-height: 1;
  font-size: 170%;
  border-bottom: 1px solid #EDEDEE;
  position: relative;
  padding-bottom: 10px;
  font-weight: normal;
  padding-top: 55px;
  margin-bottom: 30px; }
  .title01:after {
    position: absolute;
    display: block;
    content: "";
    height: 2px;
    width: 100px;
    bottom: -1px;
    left: 0;
    background: #2A4E86; }

.title02 {
  line-height: 1;
  font-size: 156%;
  font-weight: normal;
  padding-left: 20px;
  padding-top: 6px;
  padding-bottom: 6px;
  background: #F0F0F0;
  position: relative; }
  .title02:before {
    content: "";
    display: block;
    position: absolute;
    width: 3px;
    height: 70%;
    background: #2A4E86;
    top: 0;
    bottom: 0;
    left: 10px;
    margin: auto; }
  .ie .title02 {
    padding-top: 8px;
    padding-bottom: 2px; }
  @media all and (-ms-high-contrast: none) {
    .title02 {
      padding-top: 8px;
      padding-bottom: 2px; } }

.title03 {
  line-height: 1;
  font-size: 156%;
  font-weight: normal;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 12px;
  border-left: 5px solid #2A4E86; }
  .ie .title03 {
    padding-top: 6px;
    padding-bottom: 2px; }
  @media all and (-ms-high-contrast: none) {
    .title03 {
      padding-top: 6px;
      padding-bottom: 2px; } }

/******************************************
* btn
******************************************/
.btn_wrap .btn01 {
  display: block;
  text-align: center;
  border: 1px solid #EBEBEB;
  line-height: 1;
  color: #202124;
  padding: 12px 0;
  transition: .15s linear; }
  .btn_wrap .btn01 a {
    font-weight: normal; }
  .btn_wrap .btn01.arrow_r:after {
    content: " →"; }
  .btn_wrap .btn01:hover {
    background: #2A4E86;
    color: #fff; }
  .ie .btn_wrap .btn01 {
    padding-top: 14px;
    padding-bottom: 10px; }
  @media all and (-ms-high-contrast: none) {
    .btn_wrap .btn01 {
      padding-top: 14px;
      padding-bottom: 10px; } }

.btn_wrap .btn02 {
  text-align: right;
  padding-top: 15px; }
  .btn_wrap .btn02.tac {
    text-align: center; }
  .btn_wrap .btn02 a {
    font-weight: normal;
    padding: 9px 0;
    color: #fff;
    background: #2A4E86;
    width: 240px;
    display: inline-block;
    font-size: 114%;
    text-align: center;
    transition: opacity .2s; }
    .btn_wrap .btn02 a:hover {
      filter: alpha(opacity=70);
      -moz-opacity: 0.7;
      opacity: 0.7; }
    .ie .btn_wrap .btn02 a {
      padding-top: 11px;
      padding-bottom: 7px; }
    @media all and (-ms-high-contrast: none) {
      .btn_wrap .btn02 a {
        padding-top: 11px;
        padding-bottom: 7px; } }

.btn_wrap .btn03 a {
  line-height: 1;
  border: 1px solid #202124;
  display: inline-block;
  font-weight: normal;
  color: #202124;
  padding: 12px 30px;
  transition: .15s linear; }
  .btn_wrap .btn03 a:hover {
    background: #2A4E86;
    color: #fff;
    border: 1px solid #fff; }
  .ie .btn_wrap .btn03 a {
    padding-top: 16px;
    padding-bottom: 8px; }
  @media all and (-ms-high-contrast: none) {
    .btn_wrap .btn03 a {
      padding-top: 16px;
      padding-bottom: 8px; } }

.btn_wrap .btn03.arrow_r a:after {
  content: " →"; }

/******************************************
* tbl_style
******************************************/
.tbl_style01 {
  font-size: 100%;
  color: #888;
  text-align: center;
  width: 100%; }
  .tbl_style01 tr th, .tbl_style01 tr td {
    border: 1px solid #979797;
    vertical-align: middle;
    padding: 8px 15px; }
  .tbl_style01 tr th {
    background: #ddd; }

.tbl_style02 {
  font-size: 128%;
  width: 650px;
  margin: auto; }
  .tbl_style02 td, .tbl_style02 th {
    color: #202124;
    border: 1px solid #979797;
    vertical-align: middle; }
  .tbl_style02 th {
    width: 210px;
    padding: 5px 0;
    background: #ddd;
    text-align: left;
    padding-left: 50px;
    box-sizing: border-box;
    letter-spacing: 10px; }
  .tbl_style02 td {
    padding: 5px 10px 5px 40px; }

/******************************************
* common-style
******************************************/
.accent {
  color: #E60012; }

.tac {
  text-align: center; }

.tar {
  text-align: right; }

.tal {
  text-align: left; }

.err_msg {
  color: #DB1C2E; }

table.red_blue {
  table-layout: fixed;
  width: 100%;
  margin: 0 auto 100px;
  font-size: 18px;
  line-height: 1.75; }
  table.red_blue tbody tr th, table.red_blue tbody tr td {
    text-align: left;
    vertical-align: middle;
    border-color: #B7B7B7;
    border-width: 1px; }
  table.red_blue tbody tr th {
    background-color: #E8E8E8;
    font-weight: normal;
    padding: 22px 0 22px 45px;
    border-style: solid none solid solid; }
    table.red_blue tbody tr th:first-child {
      width: 211px; }
    table.red_blue tbody tr th.required::after {
      content: '※';
      margin-left: .25em; }
  table.red_blue tbody tr td {
    padding: 20px 25px;
    border-style: solid solid solid none; }
    table.red_blue tbody tr td input, table.red_blue tbody tr td select, table.red_blue tbody tr td textarea {
      -webkit-appearance: none;
      appearance: none;
      width: 100%;
      font-size: 20px;
      padding: 12px 20px;
      box-sizing: border-box; }
    table.red_blue tbody tr td input, table.red_blue tbody tr td .select_wrap, table.red_blue tbody tr td textarea {
      border: solid 1px #B7B7B7;
      border-radius: 5px; }
    table.red_blue tbody tr td input.birthday {
      width: 6em; }
      table.red_blue tbody tr td input.birthday + span {
        margin: 0 .75em 0 .25em; }
    table.red_blue tbody tr td select {
      background: none;
      border: none; }
    table.red_blue tbody tr td .select_wrap {
      position: relative;
      overflow: hidden;
      z-index: 1; }
      table.red_blue tbody tr td .select_wrap::before, table.red_blue tbody tr td .select_wrap::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        z-index: -1; }
      table.red_blue tbody tr td .select_wrap::before {
        right: 0;
        width: 14px;
        background-color: #B7B7B7; }
      table.red_blue tbody tr td .select_wrap::after {
        right: 2px;
        width: 0;
        height: 0;
        border-color: #F1F1F1 transparent;
        border-style: solid solid none;
        border-width: 8.6602540375px 5px;
        margin: auto; }
    table.red_blue tbody tr td textarea {
      min-height: 8em;
      resize: vertical; }
    table.red_blue tbody tr td .err_msg {
      font-size: 14px;
      margin: 2px 0 -7px; }
      table.red_blue tbody tr td .err_msg:empty {
        display: none; }
  table.red_blue tbody tr:first-of-type th {
    border-top: solid 5px #F3A140; }
  table.red_blue tbody tr:first-of-type td {
    border-top: solid 5px #6787C0; }
  table.red_blue tbody tr:last-of-type th {
    border-bottom: solid 5px #F3A140; }
  table.red_blue tbody tr:last-of-type td {
    border-bottom: solid 5px #6787C0; }
  @media screen and (min-width: 0) and (max-width: 767px) {
    table.red_blue {
      display: block;
      position: relative;
      border-color: #B7B7B7;
      border-style: none solid;
      border-width: 1px;
      margin-bottom: 75px; }
      table.red_blue tbody {
        display: block; }
        table.red_blue tbody tr {
          display: block; }
          table.red_blue tbody tr th, table.red_blue tbody tr td {
            display: block;
            border-width: 0; }
          table.red_blue tbody tr th {
            font-size: 15px;
            font-weight: bold;
            padding: 7px 15px;
            border-style: none; }
            table.red_blue tbody tr th:first-child {
              width: auto; }
          table.red_blue tbody tr td {
            font-size: 15px;
            padding: 15px 15px;
            border-style: none; }
            table.red_blue tbody tr td input, table.red_blue tbody tr td select, table.red_blue tbody tr td textarea {
              font-size: inherit;
              padding: 7px 10px; }
            table.red_blue tbody tr td input, table.red_blue tbody tr td .select_wrap, table.red_blue tbody tr td textarea {
              border: solid 1px #ccc; }
            table.red_blue tbody tr td input.birthday {
              width: 4em; }
              table.red_blue tbody tr td input.birthday + span {
                margin: 0 .5em 0 .125em; }
            table.red_blue tbody tr td .select_wrap::before {
              width: 14px;
              background-color: #ccc; }
            table.red_blue tbody tr td .select_wrap::after {
              right: 3px;
              border-width: 6.92820323px 4px; }
            table.red_blue tbody tr td .err_msg {
              font-size: 12px;
              margin: 2px 0 -3px; }
          table.red_blue tbody tr:first-of-type th {
            border-top: none; }
          table.red_blue tbody tr:first-of-type td {
            border-top: none; }
          table.red_blue tbody tr:last-of-type th {
            border-bottom: none; }
          table.red_blue tbody tr:last-of-type td {
            border-bottom: none; }
      table.red_blue::before, table.red_blue::after {
        content: '';
        display: block;
        width: 100%;
        height: 3px;
        padding: 0 1px;
        margin-left: -1px;
        background-color: #DB1C2E;
        background-image: linear-gradient(90deg, #DB1C2E, #DB1C2E 30%, #004D9A 30%, #004D9A); } }

.head_privacy {
  font-size: 24px;
  font-weight: normal;
  margin-bottom: 20px; }
  @media screen and (min-width: 0) and (max-width: 767px) {
    .head_privacy {
      font-size: 22px; } }

.box_privacy {
  font-size: 18px;
  line-height: 1.75;
  border: solid 1px #979797;
  padding: 40px 60px;
  box-sizing: border-box;
  height: 280px;
  overflow: auto;
  margin-bottom: 80px; }
  .box_privacy .prelude {
    margin-bottom: 40px; }
  .box_privacy .ti-1 {
    text-indent: 1em; }
  .box_privacy dl.clause dt {
    font-size: 20px;
    font-weight: bold; }
  .box_privacy dl.clause dd ul {
    list-style: none;
    padding-left: 40px;
    margin: 40px 0; }
  .box_privacy dl.clause dd.indent {
    padding-left: 1em; }
  .box_privacy dl.clause dd + dt {
    margin-top: 40px; }
  .box_privacy dl.contact dt {
    font-size: inherit;
    font-weight: normal; }
  @media screen and (min-width: 0) and (max-width: 767px) {
    .box_privacy {
      font-size: 14px;
      padding: 30px 15px;
      box-sizing: border-box;
      margin-bottom: 50px; }
      .box_privacy .prelude {
        margin-bottom: 20px; }
      .box_privacy dl.clause dt {
        font-size: 16px; }
      .box_privacy dl.clause dd ul {
        list-style: none;
        padding-left: 15px;
        margin: 15px 0; }
      .box_privacy dl.clause dd.indent {
        padding-left: 1em; }
      .box_privacy dl.clause dd + dt {
        margin-top: 20px; } }

.btn_fat {
  display: inline-block;
  background-color: #004D9A;
  color: white;
  font-size: 28px;
  font-weight: bold;
  min-width: 466px;
  text-align: center;
  padding: 20px;
  border: none;
  border-radius: 8px;
  transition: opacity .3s;
  box-sizing: border-box;
  margin-bottom: 5px; }
  .btn_fat:hover, .btn_fat:focus {
    opacity: .75; }
  .btn_fat * {
    vertical-align: middle; }
  .btn_fat.red {
    background-color: #DB1C2E; }
  .btn_fat .icon {
    margin-right: .5em; }
  .btn_fat.with_triangle::after {
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    vertical-align: middle;
    border-color: transparent currentColor;
    border-style: solid none solid solid;
    border-width: .4em .692820323em;
    margin-left: .25em; }
  @media screen and (min-width: 0) and (max-width: 767px) {
    .btn_fat {
      font-size: 22px;
      min-width: 250px;
      padding: 15px 20px; }
      .btn_fat .icon {
        width: auto;
        height: 22px;
        margin-right: .25em; } }

.visual_header {
  background-image: url(./shared/images/visual_header.jpg);
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  height: 290px; }
  @media only screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
    .visual_header {
      background-image: url(./shared/images/visual_header@2x.jpg); } }
  .visual_header .visual_title {
    color: white;
    font-size: 38px;
    font-weight: bold;
    text-align: center;
    line-height: 290px;
    white-space: nowrap; }
  @media screen and (min-width: 0) and (max-width: 767px) {
    .visual_header {
      height: 120px; }
      .visual_header .visual_title {
        font-size: 24px;
        line-height: 120px; } }

@media screen and (min-width: 0) and (max-width: 767px) {
  .parallax {
    background-attachment: scroll; } }

.agent_site_btn {
  display: inline-block;
  background-color: #CF2B1E;
  background-image: linear-gradient(110deg, #CF2B1E, #CF2B1E 49.9%, #1B4A98 50.1%, #1B4A98);
  background-repeat: no-repeat;
  color: white;
  font-size: 14px;
  padding: 7px 38px;
  transition: opacity .3s; }
  .agent_site_btn:hover, .agent_site_btn:focus {
    opacity: .75; }

.link_tel {
  color: inherit; }
  .link_tel * {
    color: inherit;
    vertical-align: middle; }

.ws-pl {
  white-space: pre-line; }

.sec {
  padding: 80px 0; }

.sec_gray {
  background-color: #F2F2F2; }

.al-c {
  text-align: center; }

.al-l {
  text-align: left; }

.al-r {
  text-align: right; }

/* parts */
#header {
  padding: 25px 0; }
  #header .container {
    display: flex;
    align-items: center;
    min-height: 90px; }
  #header .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    #header .wrapper .logo {
      transition: opacity .3s; }
      #header .wrapper .logo:hover {
        opacity: .75; }
    #header .wrapper .nav {
      flex: 1; }
    #header .wrapper .top_right {
      text-align: right;
      margin-bottom: 20px; }
      #header .wrapper .top_right ul {
        display: inline-block; }
        #header .wrapper .top_right ul li {
          display: inline-block; }
          #header .wrapper .top_right ul li * {
            vertical-align: middle; }
          #header .wrapper .top_right ul li + li {
            margin-left: 50px; }
      #header .wrapper .top_right .link_tel,
      #header .wrapper .top_right .link_mail {
        transition: opacity .3s; }
        #header .wrapper .top_right .link_tel:hover,
        #header .wrapper .top_right .link_mail:hover {
          opacity: .6; }
        #header .wrapper .top_right .link_tel .tel,
        #header .wrapper .top_right .link_mail .tel {
          font-size: 24px;
          margin-left: .25em; }
  #header #menu {
    font-size: 14px;
    text-align: right;
    margin-left: 30px; }
    #header #menu ul {
      display: inline-block; }
      #header #menu ul li {
        display: inline-block;
        padding-bottom: 7px;
        margin: 15px 0 0 20px; }
        #header #menu ul li:last-of-type {
          margin-right: 0; }
        #header #menu ul li a {
          color: inherit;
          padding: 0 10px 8px;
          border-bottom: 2px solid transparent;
          transition: border-bottom .3s; }
          #header #menu ul li a.curr, #header #menu ul li a:hover {
            color: #db1c2e;
            border-bottom: 2px solid #db1c2e; }
    #header #menu .agent_site_btn {
      display: none; }
  #header #btn_menu_open,
  #header #btn_menu_close {
    display: none; }
    #header #btn_menu_open:focus,
    #header #btn_menu_close:focus {
      outline: none; }
  @media screen and (min-width: 0) and (max-width: 767px) {
    #header {
      padding: 15px 5px 15px 15px; }
      #header .container {
        min-height: 0; }
      #header .wrapper {
        width: 100%; }
        #header .wrapper .logo {
          width: auto;
          height: 50px;
          float: left; }
        #header .wrapper .nav {
          position: absolute; }
          #header .wrapper .nav .top_right {
            display: none; }
        #header .wrapper #menu {
          position: fixed;
          background-color: #1D68B3;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          padding: 15px;
          color: white;
          font-size: 18px;
          font-weight: bold;
          text-align: center;
          margin: 0;
          opacity: 0;
          z-index: -1; }
          #header .wrapper #menu.show {
            transition: opacity .3s, z-index .3s step-start;
            opacity: 1;
            z-index: 2147483647; }
          #header .wrapper #menu.hide {
            transition: opacity .3s, z-index .3s step-end; }
          #header .wrapper #menu ul {
            display: block;
            text-align: center;
            margin-bottom: 40px; }
            #header .wrapper #menu ul li {
              display: block;
              border-top: solid 2px #D8D8D8;
              padding: 0;
              margin: 0; }
              #header .wrapper #menu ul li:last-of-type {
                border-bottom: solid 2px #D8D8D8; }
              #header .wrapper #menu ul li a {
                display: block;
                color: inherit;
                padding: 15px 0;
                border-bottom: 0; }
                #header .wrapper #menu ul li a.curr, #header .wrapper #menu ul li a:hover {
                  text-decoration: underline; }
          #header .wrapper #menu .agent_site_btn {
            display: inline-block;
            font-size: 16px;
            padding: 15px 30px; }
      #header #btn_menu_open,
      #header #btn_menu_close {
        background: none;
        border: none;
        padding: 0;
        width: 50px; }
      #header #btn_menu_open {
        display: block; }
      #header #btn_menu_close {
        display: inline-block;
        margin-bottom: 15px; } }

#footer .contact {
  background-color: #222337;
  color: white;
  padding: 80px 0;
  text-align: center; }
  #footer .contact .logo {
    margin-bottom: 20px; }
  #footer .contact .address {
    font-size: 20px;
    margin-bottom: 60px; }
  #footer .contact .tel {
    font-size: 50px;
    font-weight: bold; }
    #footer .contact .tel .link_tel {
      transition: opacity .3s; }
      #footer .contact .tel .link_tel:hover {
        opacity: .75; }
  #footer .contact .tel_warning {
    font-size: 18px;
    margin-bottom: 65px; }

#footer .copyright {
  background-color: white;
  padding: 30px 0 60px;
  text-align: right; }
  #footer .copyright small {
    font-size: 12px;
    margin-right: 80px; }

#footer .scroll_wrapper {
  max-width: 1200px;
  width: auto;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0; }
  #footer .scroll_wrapper .scroll {
    position: absolute;
    width: 57px;
    right: 0;
    bottom: 40px;
    background: none;
    padding: 0;
    border: none;
    opacity: 0;
    z-index: -1; }
    #footer .scroll_wrapper .scroll.show {
      transition: opacity .3s, z-index .3s step-start;
      opacity: 1;
      z-index: 2147483646; }
      #footer .scroll_wrapper .scroll.show:hover, #footer .scroll_wrapper .scroll.show:focus {
        opacity: .6; }
    #footer .scroll_wrapper .scroll.hide {
      transition: opacity .3s, z-index .3s step-end; }
    #footer .scroll_wrapper .scroll:focus {
      outline: none; }
    #footer .scroll_wrapper .scroll img {
      display: block; }

@media screen and (min-width: 0) and (max-width: 767px) {
  #footer .contact {
    padding: 50px 30px; }
    #footer .contact .logo {
      margin-bottom: 20px; }
    #footer .contact .address {
      font-size: 16px;
      margin-bottom: 30px; }
    #footer .contact .tel {
      font-size: 30px;
      margin-bottom: 10px; }
      #footer .contact .tel .link_tel img {
        width: auto;
        height: 35px; }
    #footer .contact .tel_warning {
      font-size: 14px;
      margin-bottom: 30px; }
  #footer .copyright {
    padding: 25px 55px 25px 0; }
    #footer .copyright small {
      margin-right: 0; }
  #footer .scroll_wrapper .scroll {
    right: 0;
    bottom: 12px;
    width: 48px; } }

/* page */
body.home .visual_wrapper {
  position: relative; }
  body.home .visual_wrapper .visual {
    position: relative;
    background-image: url(./shared/images/home/visual.jpg);
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    height: 664px; }
    @media only screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
      body.home .visual_wrapper .visual {
        background-image: url(./shared/images/home/visual@2x.jpg); } }
  body.home .visual_wrapper .visual_blocks {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: table;
    table-layout: fixed;
    width: calc(100% - 20px);
    max-width: 1200px;
    min-height: 320px; }
    body.home .visual_wrapper .visual_blocks .cell {
      display: table-cell;
      text-align: center;
      vertical-align: middle;
      color: white;
      padding: 20px 55px;
      transition: background-color .3s; }
      body.home .visual_wrapper .visual_blocks .cell:nth-child(1) {
        background-color: #292C45; }
      body.home .visual_wrapper .visual_blocks .cell:nth-child(2) {
        background-color: #013B7A; }
      body.home .visual_wrapper .visual_blocks .cell:nth-child(3) {
        background-color: #1D68B3; }
      body.home .visual_wrapper .visual_blocks .cell .block_title {
        font-size: 18px;
        font-weight: bold;
        margin: 0 0 30px; }
      body.home .visual_wrapper .visual_blocks .cell .block_icon {
        height: 100px; }
        body.home .visual_wrapper .visual_blocks .cell .block_icon img {
          display: block;
          margin: auto; }
          body.home .visual_wrapper .visual_blocks .cell .block_icon img + img {
            margin-top: 6px; }
      body.home .visual_wrapper .visual_blocks .cell .block_expl {
        font-size: 13px;
        text-align: left; }
      body.home .visual_wrapper .visual_blocks .cell:hover:nth-child(1) {
        background-color: #2f324f; }
      body.home .visual_wrapper .visual_blocks .cell:hover:nth-child(2) {
        background-color: #014289; }
      body.home .visual_wrapper .visual_blocks .cell:hover:nth-child(3) {
        background-color: #1f70c0; }

body.home .text-large {
  font-size: 18px;
  line-height: 1.75; }

body.home .sec_agent {
  padding: 50px 0; }

body.home .sec_fb {
  padding: 50px 0;
  background-image: url(./shared/images/home/sec_fb.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; }
  @media only screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
    body.home .sec_fb {
      background-image: url(./shared/images/home/sec_fb@2x.jpg); } }
  body.home .sec_fb .table {
    display: table;
    table-layout: fixed; }
    body.home .sec_fb .table .cell {
      display: table-cell;
      vertical-align: top; }
      body.home .sec_fb .table .cell:nth-child(2) {
        text-align: center; }
  body.home .sec_fb .head_fb {
    color: white;
    font-size: 30px;
    padding-left: 12px;
    border-left: solid 5px white;
    line-height: 1;
    margin-top: 10px;
    margin-bottom: 80px; }

body.home .head_serif {
  font-size: 34px;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  font-weight: normal;
  margin-bottom: 50px; }

body.home .three_points .t_row {
  display: table;
  table-layout: fixed;
  width: calc(100% - 200px);
  margin: 0 auto; }
  @media screen and (min-width: 0) and (max-width: 767px) {
    body.home .three_points .t_row {
      width: 100%; } }

body.home .three_points .col3 {
  text-align: center;
  display: table-cell; }

body.home .three_points .img_box {
  width: 100px;
  height: 100px;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  background-color: unset;
  margin: auto; }
  @media screen and (min-width: 0) and (max-width: 767px) {
    body.home .three_points .img_box {
      width: 80px;
      height: 80px; } }

body.home .three_points .tit {
  font-size: 24px;
  font-weight: bold; }
  @media screen and (min-width: 0) and (max-width: 767px) {
    body.home .three_points .tit {
      font-size: 20px; } }

body.home .head_info {
  font-size: 24px;
  font-weight: normal;
  margin-bottom: 30px; }

body.home .head_agent {
  background-color: #222337;
  color: white;
  font-size: 24px;
  font-weight: normal;
  padding: 15px 0; }
  body.home .head_agent .wrapper {
    position: relative; }
    body.home .head_agent .wrapper .agent_site_btn {
      position: absolute;
      right: 15px; }

body.home .fb_btn {
  display: inline-block;
  color: white;
  font-size: 22px;
  font-weight: bold;
  padding: 10px 20px;
  border: solid 2px white;
  transition: opacity .3s; }
  body.home .fb_btn:hover, body.home .fb_btn:focus {
    opacity: .6; }

body.home .list_info {
  list-style: none;
  padding-left: 0; }
  body.home .list_info li {
    display: table;
    table-layout: fixed;
    width: 100%;
    background-color: white;
    font-size: 14px;
    padding: 10px 30px;
    margin-bottom: 4px;
    box-sizing: border-box; }
    body.home .list_info li time {
      display: table-cell;
      width: 7em; }
    body.home .list_info li p {
      display: table-cell; }

body.home .cards {
  border-left: solid 1px #DEDDDE;
  padding-bottom: 1px;
  display: flex;
  flex-wrap: wrap; }
  body.home .cards .card_agent {
    background-color: white;
    width: 25%;
    float: left;
    padding: 20px 20px 75px;
    border-color: #DEDDDE;
    border-style: solid solid solid none;
    border-width: 1px;
    box-sizing: border-box;
    margin-bottom: -1px; }
    body.home .cards .card_agent .photo {
      position: relative;
      display: block;
      overflow: hidden;
      width: 100%;
      padding: 100% 0 0 0;
      margin-bottom: 20px; }
      body.home .cards .card_agent .photo img {
        position: absolute;
        top: 50%;
        left: 50%;
        width: auto;
        max-width: 100%;
        height: auto;
        max-height: 100%;
        margin: auto;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%); }
    body.home .cards .card_agent .name {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 30px; }
    body.home .cards .card_agent .position {
      font-size: 13px;
      font-weight: bold; }

body.home .parallax {
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover; }
  body.home .parallax.img1 {
    height: 430px;
    background-image: url(./shared/images/home/dummy_parallax.jpg); }
    @media only screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
      body.home .parallax.img1 {
        background-image: url(./shared/images/home/dummy_parallax@2x.jpg); } }

@media screen and (min-width: 0) and (max-width: 767px) {
  body.home .visual_wrapper {
    padding-bottom: 0; }
    body.home .visual_wrapper .visual {
      height: 44.266666666666667vw; }
    body.home .visual_wrapper .visual_blocks {
      position: static;
      display: block;
      width: auto;
      height: auto; }
      body.home .visual_wrapper .visual_blocks .cell {
        display: block;
        padding: 20px; }
        body.home .visual_wrapper .visual_blocks .cell .block_title {
          margin: 0 0 15px; }
        body.home .visual_wrapper .visual_blocks .cell .block_icon {
          height: auto;
          margin-bottom: 15px; }
        body.home .visual_wrapper .visual_blocks .cell .block_expl {
          font-size: 15px; }
  body.home .text-large {
    font-size: 16px; }
  body.home .sec {
    padding: 50px 0; }
  body.home .sec_agent {
    padding: 40px 0; }
  body.home .sec_fb {
    padding: 40px 10px; }
    body.home .sec_fb .table {
      display: block; }
      body.home .sec_fb .table .cell {
        display: block; }
        body.home .sec_fb .table .cell:nth-child(2) {
          width: auto;
          max-width: 500px;
          margin: 0 auto; }
          body.home .sec_fb .table .cell:nth-child(2) iframe {
            width: 100%; }
    body.home .sec_fb .head_fb {
      font-size: 24px;
      margin-bottom: 30px; }
  body.home .head_serif {
    font-size: 24px;
    margin-bottom: 50px; }
  body.home .head_agent {
    font-size: 18px;
    padding: 15px; }
    body.home .head_agent .wrapper:before, body.home .head_agent .wrapper:after {
      content: " ";
      display: table; }
    body.home .head_agent .wrapper:after {
      clear: both; }
    body.home .head_agent .wrapper span {
      float: left; }
    body.home .head_agent .wrapper .agent_site_btn {
      position: static;
      float: right;
      font-size: 11px;
      padding: 5px 20px; }
  body.home .fb_btn {
    font-size: 16px;
    font-weight: normal;
    padding: 5px 20px;
    margin-bottom: 40px; }
  body.home .cards .card_agent {
    width: 50%;
    padding: 15px 15px 30px; }
    body.home .cards .card_agent .photo {
      margin-bottom: 15px; }
    body.home .cards .card_agent .name {
      font-size: 16px;
      font-weight: normal;
      margin-bottom: 15px; }
    body.home .cards .card_agent .position {
      font-size: 12px;
      font-weight: normal; }
  body.home .parallax.img1 {
    height: 37.466666666666667vw; } }

body.about .visual_header {
  background-image: url(./shared/images/about/visual.jpg);
  height: 424px;
  max-width: 1500px;
  margin: auto; }
  @media only screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
    body.about .visual_header {
      background-image: url(./shared/images/about/visual@2x.jpg); } }

body.about .head_normal {
  margin-top: 0;
  margin-bottom: 80px; }

body.about .head_normal, body.access .head_normal {
  font-size: 36px;
  font-weight: normal;
  margin: 60px 0; }

body.about .sec_address, body.about .sec_history, body.access .sec_address, body.access .sec_history {
  padding: 100px 0 0;
  line-height: 2.5; }
  body.about .sec_address .addr_container, body.about .sec_history .addr_container, body.access .sec_address .addr_container, body.access .sec_history .addr_container {
    width: auto;
    max-width: 1315px;
    margin: 0 auto; }
    body.about .sec_address .addr_container .addr_wrapper, body.about .sec_history .addr_container .addr_wrapper, body.access .sec_address .addr_container .addr_wrapper, body.access .sec_history .addr_container .addr_wrapper {
      display: table;
      height: 700px;
      margin: 0 0 0 auto; }
      body.about .sec_address .addr_container .addr_wrapper .cell, body.about .sec_history .addr_container .addr_wrapper .cell, body.access .sec_address .addr_container .addr_wrapper .cell, body.access .sec_history .addr_container .addr_wrapper .cell {
        display: table-cell; }
        body.about .sec_address .addr_container .addr_wrapper .cell:first-child, body.about .sec_history .addr_container .addr_wrapper .cell:first-child, body.access .sec_address .addr_container .addr_wrapper .cell:first-child, body.access .sec_history .addr_container .addr_wrapper .cell:first-child {
          vertical-align: top; }
          body.about .sec_address .addr_container .addr_wrapper .cell:first-child .head_bold, body.about .sec_history .addr_container .addr_wrapper .cell:first-child .head_bold, body.access .sec_address .addr_container .addr_wrapper .cell:first-child .head_bold, body.access .sec_history .addr_container .addr_wrapper .cell:first-child .head_bold {
            font-size: 36px;
            font-weight: bold;
            margin-bottom: 10px; }
          body.about .sec_address .addr_container .addr_wrapper .cell:first-child .addr_content, body.about .sec_history .addr_container .addr_wrapper .cell:first-child .addr_content, body.access .sec_address .addr_container .addr_wrapper .cell:first-child .addr_content, body.access .sec_history .addr_container .addr_wrapper .cell:first-child .addr_content {
            width: 563px;
            font-size: 18px;
            margin-bottom: 80px; }
          body.about .sec_address .addr_container .addr_wrapper .cell:first-child .author, body.about .sec_history .addr_container .addr_wrapper .cell:first-child .author, body.access .sec_address .addr_container .addr_wrapper .cell:first-child .author, body.access .sec_history .addr_container .addr_wrapper .cell:first-child .author {
            line-height: 1.5;
            font-size: 20px;
            font-weight: bold; }
        body.about .sec_address .addr_container .addr_wrapper .cell:last-child, body.about .sec_history .addr_container .addr_wrapper .cell:last-child, body.access .sec_address .addr_container .addr_wrapper .cell:last-child, body.access .sec_history .addr_container .addr_wrapper .cell:last-child {
          width: 563px;
          vertical-align: bottom; }
          body.about .sec_address .addr_container .addr_wrapper .cell:last-child img, body.about .sec_history .addr_container .addr_wrapper .cell:last-child img, body.access .sec_address .addr_container .addr_wrapper .cell:last-child img, body.access .sec_history .addr_container .addr_wrapper .cell:last-child img {
            display: block; }

body.about .sec_profile, body.access .sec_profile {
  padding: 100px 0 0; }
  @media screen and (min-width: 0) and (max-width: 767px) {
    body.about .sec_profile, body.access .sec_profile {
      padding: 40px 15px 0; } }
  body.about .sec_profile .prof_container, body.access .sec_profile .prof_container {
    width: auto;
    max-width: 1315px;
    margin: 0 auto; }
    body.about .sec_profile .prof_container .prof_wrapper, body.access .sec_profile .prof_container .prof_wrapper {
      display: table;
      height: 700px;
      margin: 0 0 0 auto; }
      body.about .sec_profile .prof_container .prof_wrapper .head_bold, body.access .sec_profile .prof_container .prof_wrapper .head_bold {
        font-size: 36px;
        font-weight: bold;
        margin-bottom: 10px; }
      body.about .sec_profile .prof_container .prof_wrapper .text_in, body.access .sec_profile .prof_container .prof_wrapper .text_in {
        margin-top: 15px; }
        body.about .sec_profile .prof_container .prof_wrapper .text_in .sign, body.access .sec_profile .prof_container .prof_wrapper .text_in .sign {
          text-align: right;
          margin-top: 100px; }
          @media screen and (min-width: 0) and (max-width: 767px) {
            body.about .sec_profile .prof_container .prof_wrapper .text_in .sign, body.access .sec_profile .prof_container .prof_wrapper .text_in .sign {
              margin-left: 0;
              text-align: center;
              padding-bottom: 30px; } }
          body.about .sec_profile .prof_container .prof_wrapper .text_in .sign .company, body.access .sec_profile .prof_container .prof_wrapper .text_in .sign .company {
            margin-top: 15px;
            font-size: 16px;
            font-weight: normal; }
          body.about .sec_profile .prof_container .prof_wrapper .text_in .sign .name, body.access .sec_profile .prof_container .prof_wrapper .text_in .sign .name {
            font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
            font-size: 36px;
            letter-spacing: 5px; }
        body.about .sec_profile .prof_container .prof_wrapper .text_in .text, body.access .sec_profile .prof_container .prof_wrapper .text_in .text {
          line-height: 2; }

body.about .sec_history .addr_container .addr_wrapper, body.access .sec_history .addr_container .addr_wrapper {
  height: auto; }

body.about .sec_full_height, body.access .sec_full_height {
  min-height: 923px;
  height: 100vh;
  line-height: 2.5;
  background-repeat: no-repeat;
  background-size: cover; }
  body.about .sec_full_height .text-bold, body.access .sec_full_height .text-bold {
    font-size: 20px;
    font-weight: bold; }
  body.about .sec_full_height .title, body.access .sec_full_height .title {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 50px; }
  body.about .sec_full_height .expl, body.access .sec_full_height .expl {
    width: 800px;
    margin: 0 auto; }
  body.about .sec_full_height.table, body.access .sec_full_height.table {
    display: table;
    table-layout: fixed;
    width: 100%; }
    body.about .sec_full_height.table .cell, body.access .sec_full_height.table .cell {
      display: table-cell;
      vertical-align: middle; }
  body.about .sec_full_height.sec_vision, body.access .sec_full_height.sec_vision {
    color: black;
    background-color: beige;
    background-position: right top;
    background-image: url(./shared/images/about/sec_vision.jpg); }
    @media only screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
      body.about .sec_full_height.sec_vision, body.access .sec_full_height.sec_vision {
        background-image: url(./shared/images/about/sec_vision@2x.jpg); } }
    body.about .sec_full_height.sec_vision .cell, body.access .sec_full_height.sec_vision .cell {
      vertical-align: top; }
      body.about .sec_full_height.sec_vision .cell .title, body.access .sec_full_height.sec_vision .cell .title {
        margin-top: 15%; }
  body.about .sec_full_height.sec_mission, body.access .sec_full_height.sec_mission {
    color: white;
    background-color: dimgray;
    background-position: center;
    background-image: url(./shared/images/about/sec_mission.jpg); }
    @media only screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
      body.about .sec_full_height.sec_mission, body.access .sec_full_height.sec_mission {
        background-image: url(./shared/images/about/sec_mission@2x.jpg); } }
    body.about .sec_full_height.sec_mission .cell .title, body.access .sec_full_height.sec_mission .cell .title {
      margin-top: -10%; }

body.about .sec_outline, body.access .sec_outline {
  padding: 100px 0 30px; }

body.about .sec_access, body.access .sec_access {
  padding: 0 0 250px; }
  body.about .sec_access .gmap iframe, body.access .sec_access .gmap iframe {
    width: 100%;
    height: 500px; }

@media screen and (min-width: 0) and (max-width: 767px) {
  body.about .head_normal, body.access .head_normal {
    font-size: 24px;
    margin-bottom: 50px; }
  body.about .sec_address, body.about .sec_history, body.access .sec_address, body.access .sec_history {
    padding: 40px 15px 0;
    line-height: 2.25; }
    body.about .sec_address .addr_container .addr_wrapper, body.about .sec_history .addr_container .addr_wrapper, body.access .sec_address .addr_container .addr_wrapper, body.access .sec_history .addr_container .addr_wrapper {
      display: block;
      width: 100%;
      height: auto; }
      body.about .sec_address .addr_container .addr_wrapper .cell, body.about .sec_history .addr_container .addr_wrapper .cell, body.access .sec_address .addr_container .addr_wrapper .cell, body.access .sec_history .addr_container .addr_wrapper .cell {
        display: block; }
        body.about .sec_address .addr_container .addr_wrapper .cell:first-child, body.about .sec_history .addr_container .addr_wrapper .cell:first-child, body.access .sec_address .addr_container .addr_wrapper .cell:first-child, body.access .sec_history .addr_container .addr_wrapper .cell:first-child {
          vertical-align: top; }
          body.about .sec_address .addr_container .addr_wrapper .cell:first-child .head_bold, body.about .sec_history .addr_container .addr_wrapper .cell:first-child .head_bold, body.access .sec_address .addr_container .addr_wrapper .cell:first-child .head_bold, body.access .sec_history .addr_container .addr_wrapper .cell:first-child .head_bold {
            font-size: 24px;
            margin-bottom: 15px; }
          body.about .sec_address .addr_container .addr_wrapper .cell:first-child .addr_content, body.about .sec_history .addr_container .addr_wrapper .cell:first-child .addr_content, body.access .sec_address .addr_container .addr_wrapper .cell:first-child .addr_content, body.access .sec_history .addr_container .addr_wrapper .cell:first-child .addr_content {
            width: auto;
            font-size: 15px;
            margin-bottom: 30px; }
          body.about .sec_address .addr_container .addr_wrapper .cell:first-child .author, body.about .sec_history .addr_container .addr_wrapper .cell:first-child .author, body.access .sec_address .addr_container .addr_wrapper .cell:first-child .author, body.access .sec_history .addr_container .addr_wrapper .cell:first-child .author {
            font-size: 16px;
            font-weight: bold; }
        body.about .sec_address .addr_container .addr_wrapper .cell:last-child, body.about .sec_history .addr_container .addr_wrapper .cell:last-child, body.access .sec_address .addr_container .addr_wrapper .cell:last-child, body.access .sec_history .addr_container .addr_wrapper .cell:last-child {
          width: auto; }
          body.about .sec_address .addr_container .addr_wrapper .cell:last-child img, body.about .sec_history .addr_container .addr_wrapper .cell:last-child img, body.access .sec_address .addr_container .addr_wrapper .cell:last-child img, body.access .sec_history .addr_container .addr_wrapper .cell:last-child img {
            width: 320px;
            margin: 0 auto; }
  body.about .sec_full_height, body.access .sec_full_height {
    min-height: 500px;
    line-height: 2.25; }
    body.about .sec_full_height .text-bold, body.access .sec_full_height .text-bold {
      font-size: 15px; }
    body.about .sec_full_height .title, body.access .sec_full_height .title {
      font-size: 24px;
      margin-bottom: 30px; }
    body.about .sec_full_height .expl, body.access .sec_full_height .expl {
      width: auto;
      padding: 0 20px; }
    body.about .sec_full_height.sec_vision, body.access .sec_full_height.sec_vision {
      text-shadow: 0 0 2px white; }
      body.about .sec_full_height.sec_vision .cell .title, body.access .sec_full_height.sec_vision .cell .title {
        margin-top: 20%; }
  body.about .sec_outline, body.access .sec_outline {
    padding: 50px 15px 0; }
    body.about .sec_outline table.red_blue, body.access .sec_outline table.red_blue {
      margin-bottom: 0; }
  body.about .sec_access, body.access .sec_access {
    padding: 50px 0 100px; }
    body.about .sec_access .head_normal, body.access .sec_access .head_normal {
      margin-bottom: 50px; }
    body.about .sec_access .gmap iframe, body.access .sec_access .gmap iframe {
      height: 300px; } }

body.contact .wpcf7-response-output, body.complete .wpcf7-response-output {
  font-size: 18px;
  padding: 20px; }

body.contact .sec_form, body.complete .sec_form {
  padding: 100px 0 200px; }

body.contact .head_normal, body.complete .head_normal {
  font-size: 36px;
  font-weight: normal;
  margin-bottom: 80px; }

body.contact .text-large, body.complete .text-large {
  font-size: 18px;
  line-height: 1.75; }

@media screen and (min-width: 0) and (max-width: 767px) {
  body.contact .sec_form, body.complete .sec_form {
    padding: 50px 15px; }
  body.contact .head_normal, body.complete .head_normal {
    font-size: 24px;
    margin-bottom: 50px; } }

#recruit .visual_wrapper {
  position: relative;
  padding-bottom: 130px;
  background-color: #F2F0EB;
  color: white; }
  #recruit .visual_wrapper .visual {
    background-image: url(/shared/images/recruit/visual.jpg);
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    height: 760px; }
    @media only screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
      #recruit .visual_wrapper .visual {
        background-image: url(/shared/images/recruit/visual@2x.jpg); } }
  #recruit .visual_wrapper .sec_visual {
    position: absolute;
    top: 109px;
    left: 287px; }
    #recruit .visual_wrapper .sec_visual h1 {
      margin-bottom: 20px; }
      #recruit .visual_wrapper .sec_visual h1 img, #recruit .visual_wrapper .sec_visual h1 span {
        vertical-align: middle; }
      #recruit .visual_wrapper .sec_visual h1 span {
        font-size: 36px;
        font-weight: bold;
        margin-left: .25em; }
    #recruit .visual_wrapper .sec_visual p {
      font-size: 27px;
      line-height: 1.6; }
  #recruit .visual_wrapper .visual_rounds {
    position: absolute;
    bottom: -18px;
    left: 0;
    right: 0;
    margin: auto;
    display: table;
    table-layout: fixed;
    border-spacing: 18px;
    width: 1130px;
    height: 260px; }
    #recruit .visual_wrapper .visual_rounds .cell {
      display: table-cell;
      height: 260px;
      text-align: center;
      vertical-align: middle;
      border-radius: 50%; }
      #recruit .visual_wrapper .visual_rounds .cell:nth-child(1) {
        background-color: #54A9DE; }
      #recruit .visual_wrapper .visual_rounds .cell:nth-child(2) {
        background-color: #1D68B3; }
      #recruit .visual_wrapper .visual_rounds .cell:nth-child(3) {
        background-color: #013B7A; }
      #recruit .visual_wrapper .visual_rounds .cell:nth-child(4) {
        background-color: #292C45; }
      #recruit .visual_wrapper .visual_rounds .cell .round_expl {
        font-size: 28px; }

#recruit .head_bold {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 40px; }

#recruit .head_normal {
  font-size: 36px;
  font-weight: normal;
  margin-bottom: 40px; }

#recruit .text-large {
  font-size: 18px;
  line-height: 1.75; }
  #recruit .text-large + .text-large {
    margin-top: 1.75em; }

#recruit .text-small {
  font-size: 14px;
  line-height: 1.75; }
  #recruit .text-small + .text-large {
    margin-top: 1.75em; }

#recruit .taj {
  text-align: justify; }

#recruit .table {
  display: table;
  table-layout: fixed; }
  #recruit .table .cell {
    display: table-cell; }
  #recruit .table.full_width {
    width: 100%; }

#recruit .sec_about {
  padding: 100px 0 0;
  background-color: #F2F0EB; }
  #recruit .sec_about .cell:first-child {
    vertical-align: top;
    padding: 25px 100px 0 30px; }
  #recruit .sec_about .cell:last-child {
    vertical-align: bottom; }

#recruit .sec_chara {
  padding: 80px 0 120px;
  background-color: #EAEFF6; }
  #recruit .sec_chara .head_bold {
    margin-bottom: 100px; }
  #recruit .sec_chara .table .cell {
    background-repeat: no-repeat;
    background-position: right top; }
    #recruit .sec_chara .table .cell:nth-child(1) {
      background-image: url(/shared/images/recruit/sec_chara1.png);
      background-size: 177px 490px;
      background-position: right 30px top; }
      @media only screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
        #recruit .sec_chara .table .cell:nth-child(1) {
          background-image: url(/shared/images/recruit/sec_chara1@2x.png); } }
      #recruit .sec_chara .table .cell:nth-child(1) .balloon {
        border-color: #4F6187; }
        #recruit .sec_chara .table .cell:nth-child(1) .balloon .chara_title {
          color: #4F6187; }
        #recruit .sec_chara .table .cell:nth-child(1) .balloon.leftwards::before {
          border-color: transparent transparent #4F6187 #4F6187; }
        #recruit .sec_chara .table .cell:nth-child(1) .balloon.leftwards::after {
          border-color: transparent transparent white white; }
        #recruit .sec_chara .table .cell:nth-child(1) .balloon.south_west::before {
          border-color: #4F6187 transparent transparent #4F6187; }
        #recruit .sec_chara .table .cell:nth-child(1) .balloon.south_west::after {
          border-color: white transparent transparent white; }
    #recruit .sec_chara .table .cell:nth-child(2) {
      background-image: url(/shared/images/recruit/sec_chara2.png);
      background-size: 166px 488px;
      background-position: right 20px top; }
      @media only screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
        #recruit .sec_chara .table .cell:nth-child(2) {
          background-image: url(/shared/images/recruit/sec_chara2@2x.png); } }
      #recruit .sec_chara .table .cell:nth-child(2) .balloon {
        border-color: #38AAC8; }
        #recruit .sec_chara .table .cell:nth-child(2) .balloon .chara_title {
          color: #279AB8; }
        #recruit .sec_chara .table .cell:nth-child(2) .balloon.leftwards::before {
          border-color: transparent transparent #38AAC8 #38AAC8; }
        #recruit .sec_chara .table .cell:nth-child(2) .balloon.leftwards::after {
          border-color: transparent transparent white white; }
        #recruit .sec_chara .table .cell:nth-child(2) .balloon.south_west::before {
          border-color: #38AAC8 transparent transparent #38AAC8; }
        #recruit .sec_chara .table .cell:nth-child(2) .balloon.south_west::after {
          border-color: white transparent transparent white; }
    #recruit .sec_chara .table .cell:nth-child(3) {
      background-image: url(/shared/images/recruit/sec_chara3.png);
      background-size: 199px 485px; }
      @media only screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
        #recruit .sec_chara .table .cell:nth-child(3) {
          background-image: url(/shared/images/recruit/sec_chara3@2x.png); } }
      #recruit .sec_chara .table .cell:nth-child(3) .balloon {
        border-color: #C28B5D; }
        #recruit .sec_chara .table .cell:nth-child(3) .balloon .chara_title {
          color: #C28B5D; }
          #recruit .sec_chara .table .cell:nth-child(3) .balloon .chara_title::after {
            content: "\A　";
            white-space: pre; }
        #recruit .sec_chara .table .cell:nth-child(3) .balloon.leftwards::before {
          border-color: transparent transparent #C28B5D #C28B5D; }
        #recruit .sec_chara .table .cell:nth-child(3) .balloon.leftwards::after {
          border-color: transparent transparent white white; }
        #recruit .sec_chara .table .cell:nth-child(3) .balloon.south_west::before {
          border-color: #C28B5D transparent transparent #C28B5D; }
        #recruit .sec_chara .table .cell:nth-child(3) .balloon.south_west::after {
          border-color: white transparent transparent white; }
  #recruit .sec_chara .balloon {
    position: relative;
    width: 164px;
    padding: 15px;
    background-color: white;
    border-style: solid;
    border-width: 3px;
    border-radius: 8px;
    box-sizing: border-box; }
    #recruit .sec_chara .balloon .head_chara {
      font-size: 18px; }
      #recruit .sec_chara .balloon .head_chara .chara_num {
        color: #D0021B;
        margin-bottom: 5px; }
    #recruit .sec_chara .balloon + .balloon {
      margin-top: 8px; }
    #recruit .sec_chara .balloon::before, #recruit .sec_chara .balloon::after {
      position: absolute;
      content: '';
      width: 0;
      height: 0;
      border-style: solid; }
    #recruit .sec_chara .balloon.leftwards::before {
      border-width: 14px 30px;
      top: 24px;
      right: -60px; }
    #recruit .sec_chara .balloon.leftwards::after {
      border-width: 11px 23px;
      top: 27px;
      right: -46px; }
    #recruit .sec_chara .balloon.south_west::before {
      border-width: 14px 30px;
      top: 40px;
      right: -60px; }
    #recruit .sec_chara .balloon.south_west::after {
      border-width: 11px 23px;
      top: 43px;
      right: -46px; }
    @supports (-webkit-transform: skewY(0)) or (transform: skewY(0)) {
      #recruit .sec_chara .balloon.south_west::before, #recruit .sec_chara .balloon.south_west::after {
        transform: skewY(165deg); }
      #recruit .sec_chara .balloon.south_west::before {
        border-width: 16px 30px; }
      #recruit .sec_chara .balloon.south_west::after {
        border-width: 12px 23px;
        top: 45px; } }

#recruit .sec_msg {
  padding: 60px 0 0;
  background-color: #F2F0EB; }
  #recruit .sec_msg .table {
    margin-bottom: 60px; }
    #recruit .sec_msg .table .cell:first-child {
      padding-right: 20px; }
    #recruit .sec_msg .table .cell:last-child {
      padding-left: 20px; }
  #recruit .sec_msg .msg_img {
    display: block;
    margin: auto; }

#recruit .sec_form {
  padding: 50px 0 80px; }
  #recruit .sec_form .recruit_expl {
    margin-bottom: 40px; }

@media screen and (min-width: 0) and (max-width: 767px) {
  #recruit .visual_wrapper {
    background-color: #EAEFF6;
    padding-bottom: 30px; }
    #recruit .visual_wrapper .visual {
      height: 50.666666666666667vw; }
    #recruit .visual_wrapper .sec_visual {
      position: static;
      padding: 30px 15px 15px;
      text-align: center;
      color: black; }
      #recruit .visual_wrapper .sec_visual h1 {
        margin: 10px 0 20px; }
        #recruit .visual_wrapper .sec_visual h1 img {
          width: auto;
          height: 24px; }
        #recruit .visual_wrapper .sec_visual h1 span {
          font-size: 24px;
          margin-left: 0; }
      #recruit .visual_wrapper .sec_visual p {
        font-size: 16px; }
    #recruit .visual_wrapper .visual_rounds {
      position: static;
      margin: auto;
      display: block;
      width: 320px;
      height: auto;
      margin: 0 auto;
      border-spacing: 0; }
      #recruit .visual_wrapper .visual_rounds:before, #recruit .visual_wrapper .visual_rounds:after {
        content: " ";
        display: table; }
      #recruit .visual_wrapper .visual_rounds:after {
        clear: both; }
      #recruit .visual_wrapper .visual_rounds .cell {
        display: block;
        position: relative;
        float: left;
        width: 148px;
        height: 148px;
        margin: 6px; }
        #recruit .visual_wrapper .visual_rounds .cell .round_expl {
          position: absolute;
          font-size: 16px;
          top: 50%;
          left: 0;
          right: 0;
          margin: auto;
          transform: translateY(-50%); }
  #recruit .head_bold {
    font-size: 24px;
    margin-bottom: 30px; }
  #recruit .head_normal {
    font-size: 24px;
    font-weight: normal;
    margin-bottom: 40px; }
  #recruit .text-large {
    font-size: 15px; }
    #recruit .text-large + .text-large {
      margin-top: 1.5em; }
  #recruit .text-small {
    font-size: 14px;
    line-height: 1.75; }
    #recruit .text-small + .text-large {
      margin-top: 1.75em; }
  #recruit .table {
    display: block; }
    #recruit .table .cell {
      display: block; }
    #recruit .table.full_width {
      width: 100%; }
  #recruit .sec_about {
    padding: 30px 0 0;
    background-color: #F2F0EB; }
    #recruit .sec_about .cell:first-child {
      padding: 15px; }
      #recruit .sec_about .cell:first-child .head_bold {
        text-align: center; }
    #recruit .sec_about .cell:last-child img {
      display: block;
      margin: 0 auto; }
  #recruit .sec_chara {
    padding: 50px 15px;
    background-color: #EAEFF6; }
    #recruit .sec_chara .head_bold {
      margin-bottom: 50px; }
    #recruit .sec_chara .table .cell {
      background-image: none !important;
      background-repeat: no-repeat;
      background-position: right top; }
      #recruit .sec_chara .table .cell:nth-child(1) .balloon {
        border-color: #4F6187; }
        #recruit .sec_chara .table .cell:nth-child(1) .balloon .chara_title {
          color: #4F6187; }
      #recruit .sec_chara .table .cell:nth-child(2) .balloon {
        border-color: #279AB8; }
        #recruit .sec_chara .table .cell:nth-child(2) .balloon .chara_title {
          color: #279AB8; }
      #recruit .sec_chara .table .cell:nth-child(3) .balloon {
        border-color: #C28B5D; }
        #recruit .sec_chara .table .cell:nth-child(3) .balloon .chara_title {
          color: #C28B5D; }
          #recruit .sec_chara .table .cell:nth-child(3) .balloon .chara_title::after {
            content: none; }
    #recruit .sec_chara .balloon {
      width: auto; }
      #recruit .sec_chara .balloon.leftwards {
        border-style: solid solid none;
        border-radius: 8px 8px 0 0; }
      #recruit .sec_chara .balloon.south_west {
        border-style: none solid solid;
        border-radius: 0 0 8px 8px;
        padding-top: 0;
        margin-bottom: 20px; }
      #recruit .sec_chara .balloon + .balloon {
        margin-top: 0; }
      #recruit .sec_chara .balloon::before, #recruit .sec_chara .balloon::after {
        content: none; }
  #recruit .sec_msg {
    padding: 50px 15px 0; }
    #recruit .sec_msg .table {
      margin-bottom: 60px; }
      #recruit .sec_msg .table .cell:first-child {
        padding-right: 0; }
      #recruit .sec_msg .table .cell:last-child {
        padding-left: 0; }
    #recruit .sec_msg .msg_img {
      display: block;
      margin: auto; }
  #recruit .sec_form {
    padding: 50px 15px 80px; }
    #recruit .sec_form .recruit_expl {
      margin-bottom: 40px; } }

/* archive */
body.archive .head_info {
  font-size: 24px;
  font-weight: normal;
  margin-bottom: 30px; }

body.archive .list_info {
  list-style: none;
  padding-left: 0; }
  body.archive .list_info li {
    display: table;
    table-layout: fixed;
    width: 100%;
    background-color: white;
    font-size: 14px;
    padding: 10px 30px;
    margin-bottom: 4px;
    box-sizing: border-box; }
    body.archive .list_info li time {
      display: table-cell;
      width: 7em; }
    body.archive .list_info li p {
      display: table-cell; }
