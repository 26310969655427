body.home{
    .visual_wrapper {
        position: relative;
        .visual {
            position: relative;
            background-image: url(./shared/images/home/visual.jpg);
            background-position: center top;
            background-repeat: no-repeat;
            background-size: cover;
            height: 664px;
            @media only screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
                background-image: url(./shared/images/home/visual@2x.jpg);
            }
        }
        .visual_blocks {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            display: table;
            table-layout: fixed;
            width: calc(100% - 20px);
            max-width: 1200px;
            min-height: 320px;
            .cell {
                display: table-cell;
                text-align: center;
                vertical-align: middle;
                color: white;
                padding: 20px 55px;
                transition: background-color .3s;
                &:nth-child(1) { background-color: #292C45; }
                &:nth-child(2) { background-color: #013B7A; }
                &:nth-child(3) { background-color: #1D68B3; }
                .block_title {
                    font-size: 18px;
                    font-weight: bold;
                    margin: 0 0 30px;
                }
                .block_icon {
                    height: 100px;
                    img {
                        display: block;
                        margin: auto;
                        & + img {
                            margin-top: 6px;
                        }
                    }
                }
                .block_expl {
                    font-size: 13px;
                    text-align: left;
                }
                &:hover {
                    &:nth-child(1) { background-color: lighten(#292C45, 3%); }
                    &:nth-child(2) { background-color: lighten(#013B7A, 3%); }
                    &:nth-child(3) { background-color: lighten(#1D68B3, 3%); }
                }
            }
        }
    }
    .text-large {
        font-size: 18px;
        line-height: 1.75;
    }
    .sec_agent {
        padding: 50px 0;
    }
    .sec_fb {
        padding: 50px 0;
        background-image: url(./shared/images/home/sec_fb.jpg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        @media only screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
            background-image: url(./shared/images/home/sec_fb@2x.jpg);
        }
        .table {
            display: table;
            table-layout: fixed;
            .cell {
                display: table-cell;
                vertical-align: top;
                &:nth-child(2) {
                    text-align: center;
                }
            }
        }
        .head_fb {
            color: white;
            font-size: 30px;
            padding-left: 12px;
            border-left: solid 5px white;
            line-height: 1;
            margin-top: 10px;
            margin-bottom: 80px;
        }
    }
    .head_serif {
        font-size: 34px;
        font-family: $mincho;
        font-weight: normal;
        margin-bottom: 50px;
    }
    .three_points{
        .t_row{
            display: table;
            table-layout: fixed;
            width: calc(100% - 200px);
            margin:0 auto;
            @include sp{
                width: 100%;
            }
        }
        .col3{
            text-align: center;
            display: table-cell;
        }
        .img_box{
            width: 100px;
            height: 100px;
            background-repeat: no-repeat;
            background-size: 100%;
            background-position: center;
            background-color:unset;
            margin: auto;
            @include sp{
                width: 80px;
                height: 80px;
            }
        }
        .tit{
            font-size: 24px;
            font-weight: bold;
            @include sp{
                font-size: 20px;
            }
        }
    }
    .head_info {
        font-size: 24px;
        font-weight: normal;
        margin-bottom: 30px;
    }
    .head_agent {
        background-color: #222337;
        color: white;
        font-size: 24px;
        font-weight: normal;
        padding: 15px 0;
        .wrapper {
            position: relative;
            .agent_site_btn {
                position: absolute;
                right: 15px;
            }
        }
    }
    .fb_btn {
        display: inline-block;
        color: white;
        font-size: 22px;
        font-weight: bold;
        padding: 10px 20px;
        border: solid 2px white;
        transition: opacity .3s;
        &:hover, &:focus {
            opacity: .6;
        }
    }
    .list_info {
        list-style: none;
        padding-left: 0;
        li {
            display: table;
            table-layout: fixed;
            width: 100%;
            background-color: white;
            font-size: 14px;
            padding: 10px 30px;
            margin-bottom: 4px;
            box-sizing: border-box;
            time {
                display: table-cell;
                width: 7em;
            }
            p {
                display: table-cell;
            }
        }
    }
    .cards {
        border-left: solid 1px #DEDDDE;
        padding-bottom: 1px;
        display: flex;
        flex-wrap: wrap;
        .card_agent {
            background-color: white;
            width: 25%;
            float: left;
            padding: 20px 20px 75px;
            border-color: #DEDDDE;
            border-style: solid solid solid none;
            border-width: 1px;
            box-sizing: border-box;
            margin-bottom: -1px;

            .photo {
                position: relative;
                display: block;
                overflow: hidden;
                width: 100%;
                padding: 100% 0 0 0;
                margin-bottom: 20px;

                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: auto;
                    max-width: 100%;
                    height: auto;
                    max-height: 100%;
                    margin: auto;
                    -webkit-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                }
            }
            .name {
                font-size: 18px;
                font-weight: bold;
                margin-bottom: 30px;
            }
            .position {
                font-size: 13px;
                font-weight: bold;
            }
        }
    }
    .parallax {
        background-position: center top;
        background-repeat: no-repeat;
        background-size: cover;
        &.img1 {
            height: 430px;
            background-image: url(./shared/images/home/dummy_parallax.jpg);
            @media only screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
                background-image: url(./shared/images/home/dummy_parallax@2x.jpg);
            }
        }
    }

    @include sp {
        .visual_wrapper {
            padding-bottom: 0;
            .visual {
                // 画像の高さ ÷ 画像の幅 × 100
                height: 44.266666666666667vw;
            }
            .visual_blocks {
                position: static;
                display: block;
                width: auto;
                height: auto;
                .cell {
                    display: block;
                    padding: 20px;
                    .block_title {
                        margin: 0 0 15px;
                    }
                    .block_icon {
                        height: auto;
                        margin-bottom: 15px;
                    }
                    .block_expl {
                        font-size: 15px;
                    }
                }
            }
        }
        .text-large {
            font-size: 16px;
        }
        .sec {
            padding: 50px 0;
        }
        .sec_agent {
            padding: 40px 0;
        }
        .sec_fb {
            padding: 40px 10px;
            .table {
                display: block;
                .cell {
                    display: block;
                    &:nth-child(2) {
                        width: auto;
                        max-width: 500px;
                        margin: 0 auto;
                        iframe {
                            width: 100%;
                        }
                    }
                }
            }
            .head_fb {
                font-size: 24px;
                margin-bottom: 30px;
            }
        }
        .head_serif {
            font-size: 24px;
            margin-bottom: 50px;
        }
        .head_agent {
            font-size: 18px;
            padding: 15px;
            .wrapper {
                @include clearfix;
                span {
                    float: left;
                }
                .agent_site_btn {
                    position: static;
                    float: right;
                    font-size: 11px;
                    padding: 5px 20px;
                }
            }
        }
        .fb_btn {
            font-size: 16px;
            font-weight: normal;
            padding: 5px 20px;
            margin-bottom: 40px;
        }
        .cards {
            .card_agent {
                width: 50%;
                padding: 15px 15px 30px;

                .photo {
                    margin-bottom: 15px;
                }
                .name {
                    font-size: 16px;
                    font-weight: normal;
                    margin-bottom: 15px;
                }
                .position {
                    font-size: 12px;
                    font-weight: normal;
                }
            }
        }
        .parallax {
            &.img1 {
                height: 37.466666666666667vw;
            }
        }
    }
}
