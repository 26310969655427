body.contact,body.complete{
    .wpcf7-response-output{
        font-size: 18px;
        padding: 20px;
    }
    .sec_form {
        padding: 100px 0 200px;
    }
    .head_normal {
        font-size: 36px;
        font-weight: normal;
        margin-bottom: 80px;
    }
    .text-large {
        font-size: 18px;
        line-height: 1.75;
	}
	@include sp {
		.sec_form {
		    padding: 50px 15px;
		}
		.head_normal {
		    font-size: 24px;
		    margin-bottom: 50px;
		}
	}
}
