body.about{
    .visual_header {
        background-image: url(./shared/images/about/visual.jpg);
        height: 424px;
        max-width: 1500px;
        margin: auto;
        @media only screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
            background-image: url(./shared/images/about/visual@2x.jpg);
        }
    }
    .head_normal {
        margin-top: 0;
        margin-bottom: 80px;
    }
}
body.about,body.access {
    .head_normal {
        font-size: 36px;
        font-weight: normal;
        margin: 60px 0;
    }
    .sec_address {
        padding: 100px 0 0;
        line-height: 2.5;
        .addr_container {
            width: auto;
            max-width: 1315px;
            margin: 0 auto;
            .addr_wrapper {
                display: table;
                height: 700px;
                margin: 0 0 0 auto;
                .cell {
                    display: table-cell;
                    &:first-child {
                        vertical-align: top;
                        .head_bold {
                            font-size: 36px;
                            font-weight: bold;
                            margin-bottom: 10px;
                        }
                        .addr_content {
                            width: 563px;
                            font-size: 18px;
                            margin-bottom: 80px;
                        }
                        .author {
                            line-height: 1.5;
                            font-size: 20px;
                            font-weight: bold;
                        }
                    }
                    &:last-child {
                        width: 563px;
                        vertical-align: bottom;
                        img {
                            display: block;
                        }
                    }
                }
            }
        }
    }
    .sec_profile {
        padding: 100px 0 0;
        @include sp{
            padding: 40px 15px 0;
        }
        .prof_container {
            width: auto;
            max-width: 1315px;
            margin: 0 auto;
            .prof_wrapper {
                display: table;
                height: 700px;
                margin: 0 0 0 auto;
                .head_bold {
                    font-size: 36px;
                    font-weight: bold;
                    margin-bottom: 10px;
                }
                .text_in{
                    margin-top: 15px;
                    .sign{
                        text-align: right;
                        margin-top: 100px;
                        @include sp{
                            margin-left: 0;
                            text-align: center;
                            padding-bottom: 30px;
                        }
                        .company{
                            margin-top: 15px;
                            font-size: 16px;
                            font-weight: normal;
                        }
                        .name{
                            font-family: $mincho;
                            font-size: 36px;
                            letter-spacing: 5px;
                        }
                    }
                    .text{
                        line-height: 2;
                    }
                }
            }
        }
    }
    .sec_history {
        @extend .sec_address;
        .addr_container .addr_wrapper{
            height: auto;
        }
    }
    .sec_full_height {
        min-height: 923px;
        height: 100vh;
        line-height: 2.5;
        background-repeat: no-repeat;
        background-size: cover;
        .text-bold {
            font-size: 20px;
            font-weight: bold;
        }
        .title {
            font-size: 36px;
            font-weight: bold;
            margin-bottom: 50px;
        }
        .expl {
            width: 800px;
            margin: 0 auto;
        }
        &.table {
            display: table;
            table-layout: fixed;
            width: 100%;
            .cell {
                display: table-cell;
                vertical-align: middle;
            }
        }

        &.sec_vision {
            color: black;
            background-color: beige;
            background-position: right top;
            background-image: url(./shared/images/about/sec_vision.jpg);
            @media only screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
                background-image: url(./shared/images/about/sec_vision@2x.jpg);
            }
            .cell {
                vertical-align: top;
                .title {
                    margin-top: 15%;
                }
            }
        }
        &.sec_mission {
            color: white;
            background-color: dimgray;
            background-position: center;
            background-image: url(./shared/images/about/sec_mission.jpg);
            @media only screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
                background-image: url(./shared/images/about/sec_mission@2x.jpg);
            }
            .cell {
                .title {
                    margin-top: -10%;
                }
            }
        }
    }
    .sec_outline {
        padding: 100px 0 30px;
    }
    .sec_access {
        padding: 0 0 250px;
        .head_normal {
            //margin-bottom: 150px;
        }
        .gmap {
            iframe {
                width: 100%;
                height: 500px;
            }
        }
    }

    @include sp {
        .head_normal {
            font-size: 24px;
            margin-bottom: 50px;
        }
        .sec_address {
            padding: 40px 15px 0;
            line-height: 2.25;

            .addr_container {
                .addr_wrapper {
                    display: block;
                    width: 100%;
                    height: auto;

                    .cell {
                        display: block;
                        &:first-child {
                            vertical-align: top;
                            .head_bold {
                                font-size: 24px;
                                margin-bottom: 15px;
                            }
                            .addr_content {
                                width: auto;
                                font-size: 15px;
                                margin-bottom: 30px;
                            }
                            .author {
                                font-size: 16px;
                                font-weight: bold;
                            }
                        }
                        &:last-child {
                            width: auto;
                            img {
                                width: 320px;
                                margin: 0 auto;
                            }
                        }
                    }
                }
            }
        }
        .sec_full_height {
            min-height: 500px;
            line-height: 2.25;
            .text-bold {
                font-size: 15px;
            }
            .title {
                font-size: 24px;
                margin-bottom: 30px;
            }
            .expl {
                width: auto;
                padding: 0 20px;
            }

            &.sec_vision {
                text-shadow: 0 0 2px white;
                .cell {
                    .title {
                        margin-top: 20%;
                    }
                }
            }
        }
        .sec_outline {
            padding: 50px 15px 0;
            table.red_blue {
                margin-bottom: 0;
            }
        }
        .sec_access {
            padding: 50px 0 100px;
            .head_normal {
                margin-bottom: 50px;
            }
            .gmap {
                iframe {
                    height: 300px;
                }
            }
        }
    }
}
