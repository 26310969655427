#recruit{
    .visual_wrapper {
        position: relative;
        padding-bottom: 130px;
        background-color: #F2F0EB;
        color: white;
        .visual {
            background-image: url(/shared/images/recruit/visual.jpg);
            background-position: center top;
            background-repeat: no-repeat;
            background-size: cover;
            height: 760px;
            @media only screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
                background-image: url(/shared/images/recruit/visual@2x.jpg);
            }
        }
        .sec_visual {
            position: absolute;
            top: 109px;
            left: 287px;
            h1 {
                margin-bottom: 20px;
                img, span {
                    vertical-align: middle;
                }
                span {
                    font-size: 36px;
                    font-weight: bold;
                    margin-left: .25em;
                }
            }
            p {
                font-size: 27px;
                line-height: 1.6;
            }
        }
        .visual_rounds {
            position: absolute;
            bottom: -18px; //border-spacing 18px
            left: 0;
            right: 0;
            margin: auto;
            display: table;
            table-layout: fixed;
            border-spacing: 18px;
            width: 1130px;
            height: 260px;
            .cell {
                display: table-cell;
                height: 260px;
                text-align: center;
                vertical-align: middle;
                border-radius: 50%;
                &:nth-child(1) { background-color: #54A9DE; }
                &:nth-child(2) { background-color: #1D68B3; }
                &:nth-child(3) { background-color: #013B7A; }
                &:nth-child(4) { background-color: #292C45; }
                .round_expl {
                    font-size: 28px;
                }
            }
        }
    }
    .head_bold {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 40px;
    }
    .head_normal {
        font-size: 36px;
        font-weight: normal;
        margin-bottom: 40px;
    }
    .text-large {
        font-size: 18px;
        line-height: 1.75;
        & + .text-large {
            margin-top: 1.75em;
        }
    }
    .text-small {
        font-size: 14px;
        line-height: 1.75;
        & + .text-large {
            margin-top: 1.75em;
        }
    }
    .taj {
        text-align: justify;
    }
    .table {
        display: table;
        table-layout: fixed;
        .cell {
            display: table-cell;
        }
        &.full_width {
            width: 100%;
        }
    }
    .sec_about {
        padding: 100px 0 0;
        background-color: #F2F0EB;
        .cell {
            &:first-child {
                vertical-align: top;
                padding: 25px 100px 0 30px;
            }
            &:last-child {
                vertical-align: bottom;
            }
        }
    }
    .sec_chara {
        padding: 80px 0 120px;
        background-color: #EAEFF6;
        .head_bold {
            margin-bottom: 100px;
        }
        .table {
            .cell {
                background-repeat: no-repeat;
                background-position: right top;
                &:nth-child(1) {
                    background-image: url(/shared/images/recruit/sec_chara1.png);
                    background-size: 177px 490px;
                    background-position: right 30px top;
                    @media only screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
                        background-image: url(/shared/images/recruit/sec_chara1@2x.png);
                    }
                    .balloon {
                        border-color: #4F6187;
                        .chara_title {
                            color: #4F6187;
                        }
                        &.leftwards {
                            &::before { border-color: transparent transparent #4F6187 #4F6187; }
                            &::after {  border-color: transparent transparent white white; }
                        }
                        &.south_west {
                            &::before { border-color: #4F6187 transparent transparent #4F6187; }
                            &::after {  border-color: white transparent transparent white; }
                        }
                    }
                }
                &:nth-child(2) {
                    background-image: url(/shared/images/recruit/sec_chara2.png);
                    background-size: 166px 488px;
                    background-position: right 20px top;
                    @media only screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
                        background-image: url(/shared/images/recruit/sec_chara2@2x.png);
                    }
                    .balloon {
                        border-color: #38AAC8;
                        .chara_title {
                            color: #279AB8;
                        }
                        &.leftwards {
                            &::before { border-color: transparent transparent #38AAC8 #38AAC8; }
                            &::after {  border-color: transparent transparent white white; }
                        }
                        &.south_west {
                            &::before { border-color: #38AAC8 transparent transparent #38AAC8; }
                            &::after {  border-color: white transparent transparent white; }
                        }
                    }
                }
                &:nth-child(3) {
                    background-image: url(/shared/images/recruit/sec_chara3.png);
                    background-size: 199px 485px;
                    @media only screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
                        background-image: url(/shared/images/recruit/sec_chara3@2x.png);
                    }
                    .balloon {
                        border-color: #C28B5D;
                        .chara_title {
                            color: #C28B5D;
                            &::after {
                                content: "\A　";
                                white-space: pre;
                            }
                        }
                        &.leftwards {
                            &::before { border-color: transparent transparent #C28B5D #C28B5D; }
                            &::after {  border-color: transparent transparent white white; }
                        }
                        &.south_west {
                            &::before { border-color: #C28B5D transparent transparent #C28B5D; }
                            &::after {  border-color: white transparent transparent white; }
                        }
                    }
                }
            }
        }
        .balloon {
            position: relative;
            width: 164px;
            padding: 15px;
            background-color: white;
            border-style: solid;
            border-width: 3px;
            border-radius: 8px;
            box-sizing: border-box;
            .head_chara {
                font-size: 18px;
                .chara_num {
                    color: #D0021B;
                    margin-bottom: 5px;
                }
            }
            & + .balloon {
                margin-top: 8px;
            }
            &::before, &::after {
                position: absolute;
                content: '';
                width: 0;
                height: 0;
                border-style: solid;
            }
            &.leftwards {
                &::before {
                    border-width: 14px 30px;
                    top: 24px;
                    right: -60px;
                }
                &::after {
                    border-width: 11px 23px;
                    top: 27px;
                    right: -46px;
                }
            }
            &.south_west {
                &::before {
                    border-width: 14px 30px;
                    top: 40px;
                    right: -60px;
                }
                &::after {
                    border-width: 11px 23px;
                    top: 43px;
                    right: -46px;
                }
                @supports (-webkit-transform: skewY(0)) or (transform: skewY(0)) {
                    &::before, &::after {
                        transform: skewY(165deg);
                    }
                    &::before {
                        border-width: 16px 30px;
                    }
                    &::after {
                        border-width: 12px 23px;
                        top: 45px;
                    }
                }
            }
        }
    }
    .sec_msg {
        padding: 60px 0 0;
        background-color: #F2F0EB;
        .table {
            margin-bottom: 60px;
            .cell {
                &:first-child {
                    padding-right: 20px;
                }
                &:last-child {
                    padding-left: 20px;
                }
            }
        }
        .msg_img {
            display: block;
            margin: auto;
        }
    }
    .sec_form {
        padding: 50px 0 80px;
        .recruit_expl {
            margin-bottom: 40px;
        }
    }

    @include sp {
        .visual_wrapper {
            background-color: #EAEFF6;
            padding-bottom: 30px;
            .visual {
                height: 50.666666666666667vw;
            }
            .sec_visual {
                position: static;
                padding: 30px 15px 15px;
                text-align: center;
                color: black;
                h1 {
                    margin: 10px 0 20px;
                    img {
                        width: auto;
                        height: 24px;
                    }
                    span {
                        font-size: 24px;
                        margin-left: 0;
                    }
                }
                p {
                    font-size: 16px;
                }
            }
            .visual_rounds {
                @include clearfix;
                position: static;
                margin: auto;
                display: block;
                width: 320px;
                height: auto;
                margin: 0 auto;
                border-spacing: 0;
                .cell {
                    display: block;
                    position: relative;
                    float: left;
                    width: 148px;
                    height: 148px;
                    margin: 6px;
                    .round_expl {
                        position: absolute;
                        font-size: 16px;
                        top: 50%;
                        left: 0;
                        right: 0;
                        margin: auto;
                        transform: translateY(-50%);
                    }
                }
            }
        }
        .head_bold {
            font-size: 24px;
            margin-bottom: 30px;
        }
        .head_normal {
            font-size: 24px;
            font-weight: normal;
            margin-bottom: 40px;
        }
        .text-large {
            font-size: 15px;
            & + .text-large {
                margin-top: 1.5em;
            }
        }
        .text-small {
            font-size: 14px;
            line-height: 1.75;
            & + .text-large {
                margin-top: 1.75em;
            }
        }
        .table {
            display: block;
            .cell {
                display: block;
            }
            &.full_width {
                width: 100%;
            }
        }
        .sec_about {
            padding: 30px 0 0;
            background-color: #F2F0EB;
            .cell {
                &:first-child {
                    padding: 15px;
                    .head_bold {
                        text-align: center;
                    }
                }
                &:last-child {
                    img {
                        display: block;
                        margin: 0 auto;
                    }
                }
            }
        }
        .sec_chara {
            padding: 50px 15px;
            background-color: #EAEFF6;
            .head_bold {
                margin-bottom: 50px;
            }
            .table {
                .cell {
                    background-image: none !important;
                    background-repeat: no-repeat;
                    background-position: right top;
                    &:nth-child(1) {
                        .balloon {
                            border-color: #4F6187;
                            .chara_title {
                                color: #4F6187;
                            }
                        }
                    }
                    &:nth-child(2) {
                        .balloon {
                            border-color: #279AB8;
                            .chara_title {
                                color: #279AB8;
                            }
                        }
                    }
                    &:nth-child(3) {
                        .balloon {
                            border-color: #C28B5D;
                            .chara_title {
                                color: #C28B5D;
                                &::after {
                                    content: none;
                                }
                            }
                        }
                    }
                }
            }
            .balloon {
                width: auto;
                &.leftwards {
                    border-style: solid solid none;
                    border-radius: 8px 8px 0 0;
                }
                &.south_west {
                    border-style: none solid solid;
                    border-radius: 0 0 8px 8px;
                    padding-top: 0;
                    margin-bottom: 20px;
                }
                & + .balloon {
                    margin-top: 0;
                }
                &::before, &::after {
                    content: none;
                }
            }
        }
        .sec_msg {
            padding: 50px 15px 0;
            .table {
                margin-bottom: 60px;
                .cell {
                    &:first-child {
                        padding-right: 0;
                    }
                    &:last-child {
                        padding-left: 0;
                    }
                }
            }
            .msg_img {
                display: block;
                margin: auto;
            }
        }
        .sec_form {
            padding: 50px 15px 80px;
            .recruit_expl {
                margin-bottom: 40px;
            }
        }
    }
}
