#footer {
    .contact {
        background-color: #222337;
        color: white;
        padding: 80px 0;
        text-align: center;
        .logo {
            margin-bottom: 20px;
        }
        .address {
            font-size: 20px;
            margin-bottom: 60px;
        }
        .tel {
            font-size: 50px;
            font-weight: bold;
            .link_tel {
                transition: opacity .3s;
                &:hover {
                    opacity: .75;
                }
            }
        }
        .tel_warning {
            font-size: 18px;
            margin-bottom: 65px;
        }
    }
    .copyright {
        background-color: white;
        padding: 30px 0 60px;
        text-align: right;
        small {
            font-size: 12px;
            margin-right: 80px;
        }
    }
    .scroll_wrapper {
        max-width: 1200px;
        width: auto;
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;

        .scroll {
            position: absolute;
            width: 57px;
            right: 0;
            bottom: 40px;
            background: none;
            padding: 0;
            border: none;
            opacity: 0;
            z-index: -1;
            &.show {
                transition: opacity .3s, z-index .3s step-start;
                opacity: 1;
                z-index: 2147483646;
                &:hover, &:focus {
                    opacity: .6;
                }
            }
            &.hide {
                transition: opacity .3s, z-index .3s step-end;
            }
            &:focus {
                outline: none;
            }
            img {
                display: block;
            }
        }
    }

    @include sp {
        .contact {
            padding: 50px 30px;
            .logo {
                margin-bottom: 20px;
            }
            .address {
                font-size: 16px;
                margin-bottom: 30px;
            }
            .tel {
                font-size: 30px;
                margin-bottom: 10px;
                .link_tel {
                    img {
                        width: auto;
                        height: 35px;
                    }
                }
            }
            .tel_warning {
                font-size: 14px;
                margin-bottom: 30px;
            }
        }
        .copyright {
            padding: 25px 55px 25px 0;
            small {
                margin-right: 0;
            }
        }
        .scroll_wrapper {
            .scroll {
                right: 0;
                bottom: 12px;
                width: 48px;
            }
        }
    }
}
